import { cloneElement, ReactNode, useEffect } from 'react';

import { useDisclosure } from '@/hooks/useDisclosure';

import { Button } from '../Elements/Button';
import { Drawer, DrawerProps } from '../Elements/Drawer';

type FormDrawerProps = {
  isDone: boolean;
  triggerButton: React.ReactElement;
  submitButton: React.ReactElement;
  description?: ReactNode;
  title: string;
  children: React.ReactNode;
  size?: DrawerProps['size'];
};

export const FormDrawer = ({
  description,
  title,
  children,
  isDone,
  triggerButton,
  submitButton,
  size = 'md',
}: FormDrawerProps) => {
  const { close, open, isOpen } = useDisclosure();

  useEffect(() => {
    if (isDone) close();
  }, [isDone, close]);

  return (
    <>
      {cloneElement(triggerButton, { onClick: open })}
      <Drawer
        isOpen={isOpen}
        onClose={close}
        description={description}
        title={title}
        size={size}
        renderFooter={() => (
          <div className="flex w-full flex-col gap-3 md:flex-row md:justify-end">
            <Button className="flex-1 md:flex-initial" variant="alternative" onClick={close}>
              Cancel
            </Button>
            {cloneElement(submitButton, { className: 'flex-1 md:flex-initial' })}
          </div>
        )}
      >
        {children}
      </Drawer>
    </>
  );
};
