import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { WorkersTable, AddWorkerButton } from '@/features/workers';

export const TeamDashboard = () => (
  <ContentLayout title="Workers">
    <PageHeading
      description="Manage your global team."
      title="Workers 👩‍💻"
      actionComponents={<AddWorkerButton />}
    />
    <WorkersTable />
  </ContentLayout>
);
