import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

import { TPaycycle } from './getPaycycle';

type UseApprovePaycycleOptions = {
  config?: MutationConfig<typeof approvePaycycle>;
};

export type ApprovePaycycleDTO = {
  id: number;
};

const approvePaycycle = ({ id, ...data }: ApprovePaycycleDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/paycycle/${id}`, data);
};

export const useApprovePaycycle = ({ config }: UseApprovePaycycleOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('paycycle');

      const previousPaycycle = queryClient.getQueryData<TPaginatedQuery<TPaycycle>>('paycycle');

      return { previousPaycycle };
    },
    onError: (_, __, context: any) => {
      if (context?.previousPaycycle) {
        queryClient.setQueryData('paycycle', context.previousPaycycle);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('paycycle');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: approvePaycycle,
  });
};
