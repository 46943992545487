import { Well } from '../Elements';
import { Head } from '../Head';

import { BackButton } from './BackButton';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  backUrl?: string;
  hasBackNavigation?: boolean;
};

export const ContentLayout = ({
  children,
  title,
  backUrl,
  hasBackNavigation = false,
}: ContentLayoutProps) => (
  <>
    <Head title={title} />
    <main className="flex-1 py-6">
      <div className="mx-auto max-w-7xl sm:px-6">
        {hasBackNavigation && <BackButton backUrl={backUrl} />}

        <div className="space-y-6">
          <Well>
            <>{children}</>
          </Well>
        </div>
      </div>
    </main>
  </>
);
