import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { TWorker } from '@/features/workers';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Data } from '@/types';

type AssignManagerDTO = {
  userId: string;
  workerId: number;
};

type UseAssignManagerOptions = {
  config?: MutationConfig<typeof assignManager>;
};

const assignManager = ({ userId, workerId }: AssignManagerDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/users/${userId}/worker/${workerId}`);
};

export const useAssignManager = ({ config }: UseAssignManagerOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('worker');

      const previousWorker = queryClient.getQueryData<Data<TWorker>>('worker');

      return { previousWorker };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWorker) {
        queryClient.setQueryData('worker', context.previousWorker);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('worker');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: assignManager,
  });
};
