import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';
import { windowReplaceOriginUrl } from '@/utils/url';

import { getCsrfToken } from './getCsrfToken';

export type ResetPasswordDTO = {
  email: string | null;
  password: string;
  passwordConfirmation: string;
  token: string | null;
};

export const resetPassword = async (
  data: Partial<ResetPasswordDTO>
): Promise<DefaultMutationResponse> => {
  await getCsrfToken();
  return axios.post(`${API_BASE_PATH}/password/reset`, data);
};

type UseResetPasswordOptions = {
  config?: MutationConfig<typeof resetPassword>;
};

export const useResetPassword = ({ config }: UseResetPasswordOptions = {}) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get('email');
  const token = searchParams.get('token');

  const handleOnSuccess = () => {
    windowReplaceOriginUrl();
  };

  const handleOnError = () => {
    navigate('/auth/password/reset');
  };

  return useMutation({
    onError: handleOnError,
    onSuccess: handleOnSuccess,
    ...config,
    mutationFn: ({ password, passwordConfirmation }) =>
      resetPassword({ email, password, passwordConfirmation, token }),
  });
};
