import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField, PasswordRequirements } from '@/components/Form';
import { isValidStrongPassword } from '@/utils/validate';

import { useResetPassword } from '../api/resetPassword';

const schema = z
  .object({
    password: z
      .string()
      .min(1, 'Required')
      .refine(isValidStrongPassword, "Password doesn't meet requirements"),
    passwordConfirmation: z.string().min(1, 'Required'),
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    path: ['passwordConfirmation'],
    message: 'Confirmation password should match password',
  });

type LoginValuesSchema = z.infer<typeof schema>;

export const ResetPasswordForm = () => {
  const resetPassword = useResetPassword();

  const handleSubmit = async ({ password, passwordConfirmation }: LoginValuesSchema) => {
    await resetPassword.mutateAsync({ password, passwordConfirmation });
  };

  return (
    <div>
      <Form<LoginValuesSchema, typeof schema> onSubmit={handleSubmit} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password', { deps: 'passwordConfirmation' })}
            />
            <InputField
              label="Confirm Password"
              error={formState.errors['passwordConfirmation']}
              registration={register('passwordConfirmation', { deps: 'password' })}
              type="password"
            />
            <PasswordRequirements />
            <Button
              isLoading={resetPassword.isLoading}
              disabled={resetPassword.isLoading}
              type="submit"
              className="w-full"
            >
              Send
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};
