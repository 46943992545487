import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { Data, DefaultMutationResponse } from '@/types';

import { getCsrfToken } from './getCsrfToken';

export type LoginCredentialsDTO = {
  email: string;
};

export const createPasswordlessLogin = async (
  data: LoginCredentialsDTO
): Promise<Data<DefaultMutationResponse>> => {
  await getCsrfToken();
  return axios.post(`${API_BASE_PATH}/login/passwordless`, data);
};

type UseCreatePasswordlessLoginOptions = {
  config?: MutationConfig<typeof createPasswordlessLogin>;
};

export const useCreatePasswordlessLogin = ({ config }: UseCreatePasswordlessLoginOptions = {}) => {
  const navigate = useNavigate();
  const handleOnSuccess = () => {
    navigate('/login/email-sent');
  };

  return useMutation({
    onSuccess: () => {
      handleOnSuccess();
    },
    ...config,
    mutationFn: createPasswordlessLogin,
  });
};
