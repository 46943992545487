import { useMutation } from 'react-query';

import { getCsrfToken } from '@/features/auth';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

export type UpdateCompanyDTO = {
  legalName: string;
  taxId?: string;
  countries: string[];
  workers: number;
  type: string;
  address: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    countryCode: string;
  };
};

const updateCompany = async (company: UpdateCompanyDTO): Promise<DefaultMutationResponse> => {
  await getCsrfToken();
  return axios.patch(`/onboarding/company`, company, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

type UseUpdateCompanyOptions = {
  config?: MutationConfig<typeof updateCompany>;
};

export const useUpdateCompany = ({ config }: UseUpdateCompanyOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateCompany,
  });
};
