import { useEffect } from 'react';

import { Spinner } from '@/components/Elements';

import { useLogout } from '../api/logout';

export const LogoutLink = () => {
  const logoutMutation = useLogout();

  useEffect(() => {
    const handleLogout = async () => {
      await logoutMutation.mutateAsync(undefined);
    };

    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner size="xl" />
    </div>
  );
};
