import { Files, Spinner } from '@/components/Elements';

import { useWorkerDocuments } from '../api/getWorkerDocuments';

export const DocumentsAttachments = () => {
  const workerDocumentsQuery = useWorkerDocuments();

  if (workerDocumentsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerDocumentsQuery.data) return null;

  const workerDocuments = workerDocumentsQuery.data.data;

  return <Files files={workerDocuments} />;
};
