import { useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';

import { useResetPasswordEmail } from '../api/resetPasswordEmail';

const schema = z.object({
  email: z.string().min(1, 'Required').email(),
});

type LoginValues = {
  email: string;
};

export const SendResetForm = () => {
  const navigate = useNavigate();
  const resetPasswordEmail = useResetPasswordEmail({
    config: {
      onSuccess: () => {
        navigate('/auth/password/reset/email-sent');
      },
    },
  });

  return (
    <div>
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          await resetPasswordEmail.mutateAsync(values);
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <div>
              <Button
                isLoading={resetPasswordEmail.isLoading}
                disabled={resetPasswordEmail.isLoading}
                type="submit"
                className="w-full"
              >
                Send
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
