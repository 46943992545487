import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  registration: Partial<UseFormRegisterReturn>;
  value?: string;
  id: string;
};

export const CheckBoxField = ({ label, registration, value, id }: InputFieldProps) => (
  <div className="relative flex items-center">
    <div className="flex h-5 items-center">
      <input
        id={id}
        value={value}
        type="checkbox"
        className={clsx(
          'h-4 w-4 rounded border-ebony-clay-300 text-dodger-blue-600 focus:ring-dodger-blue-500'
        )}
        {...registration}
      />
    </div>
    <div className="ml-3 text-sm">
      <label htmlFor={id} className="font-medium text-ebony-clay-700">
        {label}
      </label>
    </div>
  </div>
);
