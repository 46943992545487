import { Outlet } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

export const Reports = () => {
  return (
    <ContentLayout title="Reports ">
      <PageHeading
        title="Reports 📝"
        description="Download payroll reports for an overview of your business&rsquo;s financial data."
      />
      <Outlet />
    </ContentLayout>
  );
};
