import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { User } from '../types';

export const getManagers = (): Promise<TPaginatedQuery<User[]>> =>
  axios.get(`${API_BASE_PATH}/users/managers`);

type QueryFnType = typeof getManagers;

type UseManagersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useManagers = ({ config }: UseManagersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['managers'],
    queryFn: () => getManagers(),
  });
};
