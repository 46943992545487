import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

type EmailResendDTO = unknown;

export const emailResend = async (data: EmailResendDTO): Promise<void> => {
  return axios.post(`${API_BASE_PATH}/email/resend`, data);
};

type UseEmailResendOptions = {
  config?: MutationConfig<typeof emailResend>;
};

export const useEmailResend = ({ config }: UseEmailResendOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: emailResend,
  });
};
