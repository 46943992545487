/* eslint-disable jsx-a11y/anchor-has-content */
import { DownloadIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TextButton } from '@/components/Elements/TextButton';
import { useDownloadFile } from '@/hooks/useDownloadFile';

import { getWorkersXLS } from '../../api/getWorkersXLS';

export const DownloadWorkerXLS = ({
  companyName,
  title,
}: {
  companyName: string;
  title: string;
}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const type = searchParams.get('filter[type]') || undefined;
  const status = searchParams.get('filter[status]') || undefined;
  const location = searchParams.get('filter[location]') || undefined;
  const query = searchParams.get(`filter[q]`) || undefined;

  const [buttonState, setButtonState] = useState<boolean>();

  const preDownloading = () => setButtonState(true);
  const postDownloading = () => setButtonState(false);

  const onErrorDownloadFile = () => {
    setButtonState(false);
  };

  const getFileName = () => {
    return `${title} - ${companyName}.xlsx`;
  };

  const downloadXLSFile = () => {
    return getWorkersXLS(page, sort, type, status, location, query);
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadXLSFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <TextButton disabled={buttonState} onClick={download} startIcon={<DownloadIcon />}>
      {buttonState ? 'Processing...' : 'Download XLS'}
    </TextButton>
  );
};
