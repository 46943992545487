import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { NavLink } from '../Elements';

type TabNavigationItem = {
  name: string;
  to: string;
  current?: boolean;
};

type TabNavigationProps = {
  routes: TabNavigationItem[];
};

export const TabNavigation = ({ routes }: TabNavigationProps) => {
  const navigate = useNavigate();

  return (
    <div className="sm:pb-4">
      {routes.length > 1 && (
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            onChange={(event) => {
              navigate(event.target.value.toLowerCase());
            }}
            className="block w-full rounded-full border-ebony-clay-200 focus:border-dodger-blue-500 focus:ring-dodger-blue-500"
            defaultValue={routes.find((route) => route.current)?.name}
          >
            {routes.map((route) => (
              <option key={route.name}>{route.name}</option>
            ))}
          </select>
        </div>
      )}
      <div className="hidden sm:block">
        <div className="border-b border-ebony-clay-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {routes.map((route, index) => (
              <NavLink
                to={route.to}
                key={route.name}
                end={index === 0}
                replace={true}
                className={({ isActive }) =>
                  clsx(
                    isActive
                      ? 'border-dodger-blue-500 text-dodger-blue-600'
                      : 'border-transparent text-ebony-clay-500 hover:border-ebony-clay-300 hover:text-ebony-clay-700',
                    'w-full border-b-2 py-4 px-1 text-center text-sm font-medium'
                  )
                }
              >
                {route.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
