import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { CountryMultiSelect, NativeCountrySelect, State } from '@/features/countries';
import { windowReplaceOriginUrl } from '@/utils/url';

import { UpdateCompanyDTO, useUpdateCompany } from '../api/updateCompany';

import { WorkerRadioGroup } from './WorkerRadioGroup';

const companySchema = z.object({
  legalName: z.string().min(1, 'Required'),
  taxId: z.string().optional(),
  street: z.string().min(1, 'Required'),
  city: z.string().min(1, 'Required'),
  state: z.string().min(1, 'Required'),
  postalCode: z.string().min(1, 'Required'),
  countryCode: z.string().min(1, 'Required'),
  type: z.string().min(1, 'Required'),
  workers: z
    .number({ required_error: 'Required', invalid_type_error: 'Required' })
    .min(1, 'Required'),
  countries: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .array()
    .nonempty({
      message: 'Required',
    }),
});

export type TUpdateCompanySchema = z.infer<typeof companySchema>;

const updateCompanyDTO = (values: TUpdateCompanySchema): UpdateCompanyDTO => {
  const { legalName, taxId, workers, type, countries: countriesOptions, ...address } = values;
  const parsedTaxId = taxId || undefined;
  const countries = countriesOptions.map((country) => country.label);

  return {
    legalName,
    taxId: parsedTaxId,
    countries,
    workers,
    type,
    address,
  };
};

export const UpdateCompanyForm = () => {
  const updateCompanyMutation = useUpdateCompany();

  const handleOnSubmit = async (values: TUpdateCompanySchema) => {
    await updateCompanyMutation.mutateAsync(updateCompanyDTO(values), {
      onSettled: (data) => {
        if (data) windowReplaceOriginUrl();
      },
    });
  };

  return (
    <Form<TUpdateCompanySchema, typeof companySchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={companySchema}
      className="space-y-8 divide-y divide-gray-200"
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register, watch, control }) => {
        const countryCode = watch('countryCode');
        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Tell us about your company
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Create your account to get started with onboarding employees and contractors.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-6">
                  <CountryMultiSelect name="countries" />
                </div>

                <div className="sm:col-span-6">
                  <WorkerRadioGroup control={control} />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="How many workers do you plan to hire?"
                    type="number"
                    error={formState.errors['workers']}
                    registration={register('workers', { valueAsNumber: true })}
                  />
                </div>
              </div>
            </div>
            <div className="pt-8">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Billing and Registration
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Before you can begin onboarding, we need just a few more details about your
                  company.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputField
                    label="Legal name"
                    error={formState.errors['legalName']}
                    registration={register('legalName')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Tax ID (optional)"
                    error={formState.errors['taxId']}
                    registration={register('taxId')}
                  />
                </div>
                <div className="sm:col-span-4">
                  <NativeCountrySelect
                    label="Country"
                    error={formState.errors['countryCode']}
                    registration={register('countryCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <State countryCode={countryCode} />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Zip / Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
              </div>
            </div>
            <div className="pt-5">
              <div className="flex justify-end">
                <Button
                  isLoading={updateCompanyMutation.isLoading}
                  disabled={updateCompanyMutation.isLoading}
                  type="submit"
                  size="lg"
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        );
      }}
    </Form>
  );
};
