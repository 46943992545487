import { useAuth } from '@/lib/auth';

export const InactiveState = () => {
  const { user } = useAuth();

  if (user?.data.company.status === 'active') return null;
  return (
    <div className="bg-froly-100 p-6">
      <p className="text-xs font-medium text-froly-800">
        ⚠️ You can begin adding employees or contractors to the Via platform immediately, but you
        will not be able to complete the onboarding process until the Master Services Agreement
        (MSA) is signed and reviewed.
      </p>
    </div>
  );
};
