import { Filter } from '@/components/Elements/Filter';

type Option = {
  label: string;
  value: string;
};

const options: Option[] = [
  {
    label: 'USD',
    value: 'usd',
  },
  {
    label: 'Local currency',
    value: 'local',
  },
];

export const SelectPaycycleCurrency = () => (
  <Filter name="currencyType" options={options} className="w-auto pr-8" />
);
