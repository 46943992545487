import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { TWorker } from '../types';

export const getWorkers = (
  page?: string,
  sort?: string,
  type?: string,
  status?: string,
  location?: string,
  query?: string
): Promise<TPaginatedQuery<TWorker[]>> =>
  axios.get(`${API_BASE_PATH}/workers`, {
    params: {
      sort,
      page,
      'filter[type]': type,
      'filter[status]': status,
      'filter[location]': location,
      'filter[q]': query,
    },
  });

type QueryFnType = typeof getWorkers;

type UseWorkersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useWorkers = ({ config }: UseWorkersOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const type = searchParams.get('filter[type]') || undefined;
  const status = searchParams.get('filter[status]') || undefined;
  const location = searchParams.get('filter[location]') || undefined;
  const query = searchParams.get('filter[q]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['workers', page, sort, type, status, location, query],
    queryFn: () => getWorkers(page, sort, type, status, location, query),
  });
};
