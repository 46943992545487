import { Filter } from '@/components/Elements/Filter';
import { FieldWrapper } from '@/components/Form/FieldWrapper';

const tableFilters: { name: string; options: { label: string; value: string }[] }[] = [
  {
    name: 'role',
    options: [
      {
        label: 'all',
        value: '',
      },
      {
        label: 'super admin',
        value: 'superAdmin',
      },
      {
        label: 'admin',
        value: 'admin',
      },
      {
        label: 'manager',
        value: 'manager',
      },
      {
        label: 'viewer',
        value: 'viewer',
      },
    ],
  },
];

export const Filters = () => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {tableFilters.map((filter) => (
        <div className="col-span-1" key={filter.name}>
          <FieldWrapper label={filter.name}>
            <Filter name={filter.name} options={filter.options} />
          </FieldWrapper>
        </div>
      ))}
    </div>
  );
};
