import { Switch as HeadlessSwitch } from '@headlessui/react';
import clsx from 'clsx';

type SwitchProps = {
  checked: boolean;
  isLoading?: boolean;
  onChange?(checked: SwitchProps['checked']): void;
  label: string;
  description?: string;
};

export const Switch = ({ checked, isLoading, onChange, label, description }: SwitchProps) => {
  return (
    <HeadlessSwitch.Group as="div" className="flex items-center justify-between py-6 ">
      <div className="flex flex-col">
        <HeadlessSwitch.Label as="p" className="text-sm font-medium text-ebony-clay-900" passive>
          {label}
        </HeadlessSwitch.Label>
        <HeadlessSwitch.Description className="text-sm text-ebony-clay-500">
          {description}
        </HeadlessSwitch.Description>
      </div>
      <HeadlessSwitch
        disabled={isLoading}
        checked={checked}
        onChange={onChange}
        className={clsx(
          checked ? 'bg-dodger-blue-500' : 'bg-ebony-clay-200',
          'relative ml-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-dodger-blue-500 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={clsx(
            isLoading && 'animate-pulse',
            checked ? 'translate-x-5' : 'translate-x-0',
            'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out disabled:bg-black'
          )}
        />
      </HeadlessSwitch>
    </HeadlessSwitch.Group>
  );
};
