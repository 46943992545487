import { Spinner } from '@/components/Elements';

import { useLoginLink } from '../api/loginLink';

export const ValidateLink = () => {
  const loginLinkQuery = useLoginLink();

  if (!loginLinkQuery.data) return null;

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner size="xl" />
    </div>
  );
};
