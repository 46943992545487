import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef, ReactElement } from 'react';

import { Spinner } from '@/components/Elements/Spinner';

const variants = {
  brand:
    'text-white bg-ebony-clay-900 hover:bg-ebony-clay-800 focus:ring-ebony-clay-800 disabled:hover:bg-ebony-clay-900',
  alternative:
    'border border-ebony-clay-300 bg-white text-ebony-clay-700 hover:text-ebony-clay-500 focus:ring-dodger-blue-500',
  primary:
    'text-white bg-dodger-blue-600 hover:bg-dodger-blue-500 focus:ring-dodger-blue-500 disabled:hover:bg-dodger-blue-600',
  secondary:
    'text-froly-400 bg-white border border-froly-400 hover:bg-froly-400 hover:text-white focus:ring-froly-300 disabled:hover:bg-white disabled:hover:text-froly-400',
  danger:
    'text-white bg-froly-600 hover:bg-froly-500 focus:ring-froly-500 disabled:hover:bg-froly-600',
};

const sizes = {
  xs: 'px-1 py-1.5 text-xs',
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-4 py-2',
  lg: 'px-6 py-3 text-base',
};

type IconProps =
  | { startIcon: ReactElement; endIcon?: never }
  | { endIcon: ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
} & IconProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'inline-flex items-center justify-center',
          'rounded-full font-medium shadow-sm',
          'focus:outline-none focus:ring-2 focus:ring-offset-2',
          'disabled:opacity-50',
          variants[variant],
          sizes[size],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner variant="light" size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';
