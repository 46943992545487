import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

type UseMutePendingNotificationsOptions = {
  config?: MutationConfig<typeof mutePendingNotifications>;
};

export type MutePendingNotificationsDTO = {
  mailSubscription: boolean;
};

const mutePendingNotifications = (data: MutePendingNotificationsDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/notifications`, data);
};

export const useMutePendingNotifications = ({
  config,
}: UseMutePendingNotificationsOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: mutePendingNotifications,
  });
};
