import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { TWorker } from '@/features/workers';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

type UpdateUserDTO = {
  id: number;
  role: string;
};

type UseUpdateUserOptions = {
  config?: MutationConfig<typeof updateUser>;
};

const updateUser = ({ id, ...formData }: UpdateUserDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/users/${id}`, formData);
};

export const useUpdateUser = ({ config }: UseUpdateUserOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('users');

      const previousUsers = queryClient.getQueryData<TPaginatedQuery<TWorker[]>>('users');

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData('users', context.previousUsers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('users');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: updateUser,
  });
};
