import { Spinner } from '@/components/Elements';
import { PageHeading } from '@/components/Layout/PageHeading';

import { useWorker } from '../api/getWorker';

export const WorkerBanner = () => {
  const workerQuery = useWorker();

  if (workerQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerQuery.data) return null;

  const worker = workerQuery.data.data;

  return (
    <PageHeading
      avatarUrl={worker.avatar}
      title={worker.fullName}
      description={worker.offer.jobTitle}
    />
  );
};
