import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { label, className, registration, error, helperText } = props;
  return (
    <FieldWrapper label={label} error={error} helperText={helperText}>
      <textarea
        rows={5}
        className={clsx(
          'block w-full appearance-none',
          'rounded-3xl border border-gray-300',
          'px-4 py-3 shadow-sm',
          'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
          'sm:text-sm',
          className
        )}
        {...registration}
      />
    </FieldWrapper>
  );
};
