import { InformationCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { TextButton } from '@/components/Elements/TextButton';
import { CurrencyValue } from '@/features/countries';

import { useProcessAdditionalCompensation } from '../api/processAdditionalCompensation';

type AcceptAdditionalCompensationProps = {
  id: number;
  fullName: string;
  amount: string;
  createdAt: string;
  requestType: string;
  description: string;
  currencyCode: string;
};

type InfoCardProps = {
  title: string;
  value: ReactNode;
};

const triggerButton = <TextButton>Accept</TextButton>;

const InfoCard = ({ title, value }: InfoCardProps) => (
  <div className="col-span-1 bg-ebony-clay-50 p-4 shadow-sm">
    <dt className="text-sm font-medium text-ebony-clay-900">{title}</dt>
    <dd className="mt-1 text-sm text-ebony-clay-600">{value}</dd>
  </div>
);

export const AcceptAdditionalCompensation = ({
  id,
  fullName,
  amount,
  createdAt,
  requestType,
  description,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  currencyCode,
}: AcceptAdditionalCompensationProps) => {
  const processAdditionalCompensationRequest = useProcessAdditionalCompensation();

  const handleConfirmationClick = async () => {
    await processAdditionalCompensationRequest.mutateAsync({ id, status: 'accepted' });
  };

  return (
    <ModalDialog
      icon={<InformationCircleIcon className="h-6 w-6 text-dodger-blue-600" />}
      confirmButton={
        <Button
          isLoading={processAdditionalCompensationRequest.isLoading}
          onClick={handleConfirmationClick}
          disabled={
            processAdditionalCompensationRequest.isLoading ||
            processAdditionalCompensationRequest.isSuccess
          }
        >
          Accept
        </Button>
      }
      isDone={processAdditionalCompensationRequest.isSuccess}
      title="Accept additional compensation request?"
      triggerButton={triggerButton}
    >
      <dl className="grid gap-2 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <InfoCard title="Worker" value={fullName} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard
            title="Amount"
            value={<CurrencyValue value={amount} currencyCode={currencyCode} />}
          />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Date" value={createdAt} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Type" value={<span className="capitalize">{requestType}</span>} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Description" value={description} />
        </div>
      </dl>
    </ModalDialog>
  );
};
