import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { Country } from '../types';

export const getOfferCountries = (): Promise<Data<Country[]>> =>
  axios.get(`/countries`, {
    params: {
      'filter[hasOffers]': true,
    },
  });

type QueryFnType = typeof getOfferCountries;

type UseOfferCountriesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useOfferCountries = ({ config }: UseOfferCountriesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['offer-countries'],
    queryFn: () => getOfferCountries(),
  });
};
