import clsx from 'clsx';
import { cloneElement, DetailedHTMLProps } from 'react';

const variants = {
  primary: 'text-dodger-blue-600 hover:text-dodger-blue-900',
  danger: 'text-froly-600 hover:text-froly-900',
  secondary: 'text-ebony-clay-500 hover:text-ebony-clay-600',
};

type TextButtonProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant?: keyof typeof variants;
} & IconProps;

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export const TextButton = ({
  children,
  endIcon,
  startIcon,
  variant = 'primary',
  ...props
}: TextButtonProps) => (
  <button
    {...props}
    className={clsx(
      'inline-flex appearance-none items-center justify-center text-sm font-medium',
      variants[variant]
    )}
  >
    {startIcon && cloneElement(startIcon, { className: 'h-4 w-6' })} {children}{' '}
    {endIcon && cloneElement(endIcon, { className: 'h-4 w-6' })}
  </button>
);
