import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import { useController, UseControllerProps } from 'react-hook-form';

import { FieldWrapper } from '@/components/Form/FieldWrapper';

import { TUpdateCompanySchema } from './UpdateCompanyForm';

const workerTypes = [
  {
    label: 'Employees',
    value: 'employees',
  },
  {
    label: 'Contractors',
    value: 'contractors',
  },
  {
    label: 'Both',
    value: 'all',
  },
];

export const WorkerRadioGroup = ({
  control,
}: Pick<UseControllerProps<TUpdateCompanySchema>, 'control'>) => {
  const { field, formState } = useController({
    control,
    name: 'type',
    defaultValue: '',
  });
  return (
    <FieldWrapper label="How are your workers classified?" error={formState.errors.type}>
      <RadioGroup {...field}>
        <div className="flex flex-col gap-4 sm:flex-row">
          {workerTypes.map((worker) => (
            <RadioGroup.Option
              key={worker.value}
              value={worker.value}
              className={({ checked, active }) =>
                clsx(
                  checked ? 'border-transparent text-dodger-blue-600' : 'border-gray-300',
                  active
                    ? 'border-dodger-blue-500 text-dodger-blue-600 ring-2 ring-dodger-blue-500'
                    : '',
                  'relative block flex-1 cursor-pointer rounded-3xl border bg-white py-8 shadow-sm focus:outline-none sm:py-16'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <RadioGroup.Label
                    as="p"
                    className="text-ebony-900 text-center text-base font-medium"
                  >
                    {worker.label}
                  </RadioGroup.Label>
                  <span
                    className={clsx(
                      active ? 'border' : 'border-2',
                      checked ? 'border-dodger-blue-500' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-3xl'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </FieldWrapper>
  );
};
