import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { TWorker } from '../types';

export const getWorker = (workerId?: string): Promise<Data<TWorker>> =>
  axios.get(`${API_BASE_PATH}/workers/${workerId}`);

type QueryFnType = typeof getWorker;

type UseWorkerOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useWorker = ({ config }: UseWorkerOptions = {}) => {
  const { workerId } = useParams();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['worker', workerId],
    queryFn: () => getWorker(workerId),
  });
};
