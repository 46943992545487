import { useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { CheckBoxField, Form, InputField, PasswordRequirements } from '@/components/Form';
import { RegistrationDTO } from '@/features/auth';
import { windowReplaceOriginUrl } from '@/utils/url';
import { isValidStrongPassword } from '@/utils/validate';

import { register } from '../api/register';

const schema = z
  .object({
    email: z.string().min(1, 'Required').email(),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    role: z.string().min(1, 'Required'),
    accepted: z.boolean(),
    password: z
      .string()
      .min(1, 'Required')
      .refine(isValidStrongPassword, "Password doesn't meet requirements"),
    passwordConfirmation: z.string().min(1, 'Required'),
  })
  .refine(({ password, passwordConfirmation }) => password === passwordConfirmation, {
    path: ['passwordConfirmation'],
    message: 'Confirmation password should match password',
  });

type LoginValuesSchema = z.infer<typeof schema>;

const registrationFormDTO = (values: LoginValuesSchema): RegistrationDTO => {
  return {
    ...values,
  };
};

const Agreement = () => (
  <span>
    By creating an account, you agree to the{' '}
    <a
      href="https://via.work/terms-and-conditions"
      className="text-sm font-semibold underline"
      target="_blank"
      rel="noreferrer"
    >
      Terms and Conditions
    </a>{' '}
    and{' '}
    <a
      href="https://via.work/privacy-policy"
      className="text-sm font-semibold underline"
      target="_blank"
      rel="noreferrer"
    >
      Privacy Policy
    </a>{' '}
    of Via.
  </span>
);

export const RegisterForm = (
  defaultValues: Required<Pick<RegistrationDTO, 'firstName' | 'lastName' | 'email'>>
) => {
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  return (
    <div>
      <Form<LoginValuesSchema, typeof schema>
        onSubmit={async (values) => {
          setIsRegistering(true);
          await register(registrationFormDTO(values)).finally(() => {
            setIsRegistering(false);
            windowReplaceOriginUrl();
          });
        }}
        schema={schema}
        className="space-y-8 divide-y divide-gray-200"
        options={{
          defaultValues,
        }}
      >
        {({ register, formState, watch }) => {
          const isAccepted = watch('accepted');

          return (
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Create personal account
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Create your account to get started with your company onboarding.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <InputField
                      label="First name"
                      error={formState.errors['firstName']}
                      registration={register('firstName')}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <InputField
                      label="Last name"
                      error={formState.errors['lastName']}
                      registration={register('lastName')}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <InputField
                      label="Company email"
                      error={formState.errors['email']}
                      registration={register('email')}
                      type="email"
                      disabled
                      className="disabled:bg-ebony-clay-100"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <InputField
                      label="Role or job title"
                      error={formState.errors['role']}
                      registration={register('role')}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="pt-8 text-lg font-medium leading-6 text-gray-900">
                    Create your password
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Select a secure password that meets all of the requirements listed at the end of
                    this section.
                  </p>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <InputField
                      type="password"
                      label="Password"
                      error={formState.errors['password']}
                      registration={register('password', { deps: 'passwordConfirmation' })}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <InputField
                      label="Confirm Password"
                      error={formState.errors['passwordConfirmation']}
                      registration={register('passwordConfirmation', { deps: 'password' })}
                      type="password"
                    />
                  </div>
                </div>
                <PasswordRequirements />
              </div>
              <div className="pt-5">
                <div className="flex justify-between">
                  <CheckBoxField
                    id="agreement"
                    label={<Agreement />}
                    error={formState.errors['accepted']}
                    registration={register('accepted')}
                  />
                  <Button
                    isLoading={isRegistering}
                    disabled={isRegistering || !isAccepted}
                    type="submit"
                    size="lg"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};
