import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';

import { useOnboardWorker } from '../../api/onboardWorker';

import { ActionForm, FORM_ID } from './ActionForm';
import { onboardContractorDTO } from './onboardContractorDTO';
import { OnboardContractorSchema } from './schema';

type OnboardContractorFormProps = {
  countryCode: string;
};

export const OnboardContractorForm = ({ countryCode }: OnboardContractorFormProps) => {
  const navigate = useNavigate();
  const onboardWorkerMutation = useOnboardWorker();

  const handleOnSubmit = async (formData: OnboardContractorSchema) => {
    await onboardWorkerMutation.mutateAsync(onboardContractorDTO({ ...formData, countryCode }));
  };

  if (onboardWorkerMutation.isSuccess) {
    navigate('/app/team');
  }

  return (
    <ActionForm
      onSubmit={handleOnSubmit}
      submitButton={
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={onboardWorkerMutation.isLoading}
          disabled={onboardWorkerMutation.isLoading}
        >
          Submit
        </Button>
      }
    />
  );
};
