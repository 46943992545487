import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { windowReplaceOriginUrl } from '@/utils/url';

import { getCsrfToken } from './getCsrfToken';

export type LoginLinkDTO = {
  id: string | null;
  hash: string | null;
  expires: string | null;
  signature: string | null;
};

export const loginLink = async (data: LoginLinkDTO): Promise<null> => {
  await getCsrfToken();
  return axios.get(`${API_BASE_PATH}/login/link/${data.id}/${data.hash}`, {
    params: {
      expires: data.expires,
      signature: data.signature,
    },
  });
};

type QueryFnType = typeof loginLink;

type UseLoginWithLinkOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLoginLink = ({ config }: UseLoginWithLinkOptions = {}) => {
  const [searchParams] = useSearchParams();

  const signature = searchParams.get('signature');
  const hash = searchParams.get('hash');
  const expires = searchParams.get('expires');
  const id = searchParams.get('id');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['login-link'],
    queryFn: () => loginLink({ expires, hash, id, signature }),
    onSettled: () => {
      windowReplaceOriginUrl();
    },
  });
};
