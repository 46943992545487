import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import {
  getUser,
  LoginCredentialsDTO,
  loginWithEmailAndPassword,
  logout,
  registration,
  RegistrationDTO,
  User,
} from '@/features/auth';
import { Data } from '@/types';
import { windowReplaceOriginUrl } from '@/utils/url';

const loadUser = async () => await getUser();

const loginFn = async (data: LoginCredentialsDTO) => {
  await loginWithEmailAndPassword(data);
  windowReplaceOriginUrl();
  return null;
};

const logoutFn = async () => {
  await logout();
};

const registerFn = async (data: RegistrationDTO) => {
  await registration(data);
  windowReplaceOriginUrl();
  return null;
};

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  Data<User> | null,
  unknown,
  LoginCredentialsDTO,
  unknown
>(authConfig);
