import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

import { TWorker } from '../types';

type UseOnboardWorkerOptions = {
  config?: MutationConfig<typeof onboardWorker>;
};

export interface OnboardWorkerDTO {
  worker: Worker;
  offer: Offer;
}

interface Offer {
  type: string;
  countryCode: string;
  jobTitle: string;
  quotedGrossSalary: string;
  quotedCurrencyCode: string;
  grossSalaryPeriod: string;
  managerId?: string;
  startsAt: string;
  endsAt?: string;
  servicesDescription: string;
  team?: string;
  statutoryBenefits?: StatutoryBenefits;
  extraBenefits: ExtraBenefits;
}

interface ExtraBenefits {
  needsBenefits: boolean;
  newGroupBenefit: boolean;
  questionnaire?: Questionnaire;
}

interface Questionnaire {
  dependentLife: boolean;
  health: boolean;
  dental: boolean;
  savingsFund: boolean;
  groceryAllowance: boolean;
}

interface StatutoryBenefits {
  bonusDays?: number;
  vacationDays?: number;
  vacationsPremiumPercentage?: number;
  pto?: string;
}

interface Worker {
  firstName: string;
  lastName: string;
  email: string;
}

const onboardWorker = (data: OnboardWorkerDTO): Promise<void> => {
  return axios.post(`${API_BASE_PATH}/workers`, data);
};

export const useOnboardWorker = ({ config }: UseOnboardWorkerOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('workers');

      const previousWorkers = queryClient.getQueryData<TPaginatedQuery<TWorker[]>>('workers');

      return { previousWorkers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWorkers) {
        queryClient.setQueryData('workers', context.previousWorkers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workers');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: onboardWorker,
  });
};
