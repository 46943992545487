import { cloneElement } from 'react';

import { SelectFieldProps } from '@/components/Form';
import { useCountries } from '@/features/countries';

export const CurrencySelect = ({ children }: { children: React.ReactElement }) => {
  const countriesQuery = useCountries({
    config: {
      staleTime: Infinity,
    },
  });

  if (countriesQuery.isLoading) return cloneElement<SelectFieldProps>(children, { disabled: true });

  if (!countriesQuery.data) return children;

  const { data: countries } = countriesQuery.data;

  const currencies = countries.map((country) => country.currency).sort();

  const currencyOptions = Array.from(new Set(currencies), (currency) => ({
    label: currency,
    value: currency,
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...currencyOptions],
  });
};
