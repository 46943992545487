import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';

import { useAuth } from '@/lib/auth';

type UserNavigationItem = {
  name: string;
  href: string;
};

const MenuItems = () => {
  const userNavigation: UserNavigationItem[] = [
    {
      name: 'Settings',
      href: 'settings',
    },
    {
      name: 'Sign out',
      href: '/logout',
    },
  ];

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        as="div"
        className="absolute right-0 mt-2 w-48 origin-top-right rounded-3xl bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        {userNavigation.map((item) => (
          <Menu.Item
            key={item.name}
            as="a"
            href={item.href}
            className="block rounded-3xl px-4 py-2 text-sm text-ebony-clay-900 hover:bg-froly-100 hover:text-froly-500"
          >
            {item.name}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Transition>
  );
};

export const ProfileMenu = () => {
  const { user } = useAuth();

  return (
    <div className="flex items-center">
      {/* Profile picture */}
      <span className="sr-only">Open user menu</span>
      <span className="text-sm font-medium tracking-wide">{user?.data.firstName}</span>
      {/* Profile dropdown */}
      <Menu as="div" className="relative ml-1">
        <Menu.Button
          as="button"
          className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:outline-dodger-blue-600 focus:ring-2 focus:ring-dodger-blue-600 focus:ring-offset-2"
        >
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <MenuItems />
      </Menu>
    </div>
  );
};
