import { Spinner } from '@/components/Elements';
import { pluralizeWord } from '@/utils/format';

import { useWorker } from '../api/getWorker';

import { BenefitAttachments } from './BenefitAttachments';

export const WorkerBenefitsDetails = () => {
  const workerQuery = useWorker();

  if (workerQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerQuery.data) return null;

  const worker = workerQuery.data.data;

  const totalDays =
    typeof worker.offer.ptoBalance?.vacationDays === 'number'
      ? `${worker.offer.ptoBalance.vacationDays} day${pluralizeWord(
          worker.offer.ptoBalance.vacationDays
        )}`
      : undefined;

  const usedDays =
    typeof worker.offer.ptoBalance?.daysTaken === 'number'
      ? `${worker.offer.ptoBalance.daysTaken} day${pluralizeWord(
          worker.offer.ptoBalance.daysTaken
        )}`
      : undefined;

  return (
    <>
      {worker.offer.ptoBalance && (
        <>
          <h3 className="text-lg font-medium text-ebony-clay-900">Paid time off</h3>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {worker.offer.ptoBalance.type && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-ebony-clay-500">Policy</dt>
                <dd className="mt-1 text-sm capitalize text-ebony-clay-900">
                  {worker.offer.ptoBalance.type}
                </dd>
              </div>
            )}
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Period</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">{worker.offer.ptoBalance.period}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Total</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">{totalDays}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-ebony-clay-500">Used</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">{usedDays}</dd>
            </div>
          </dl>
        </>
      )}
      {worker.offer.benefits.statutory && (
        <>
          <h3 className="text-lg font-medium text-ebony-clay-900">Statutory benefits</h3>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {worker.offer.benefits.statutory.bonusDays && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-ebony-clay-500">
                  Christmas bonus / aguinaldo
                </dt>
                <dd className="mt-1 text-sm text-ebony-clay-900">
                  {worker.offer.benefits.statutory.bonusDays} days
                </dd>
              </div>
            )}
            {worker.offer.benefits.statutory.vacationsPremiumPercentage && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-ebony-clay-500">Vacation premium</dt>
                <dd className="mt-1 text-sm text-ebony-clay-900">
                  {worker.offer.benefits.statutory.vacationsPremiumPercentage}%
                </dd>
              </div>
            )}
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-ebony-clay-500">Attachments</dt>
              <dd className="mt-1 text-sm text-ebony-clay-900">
                <BenefitAttachments />
              </dd>
            </div>
          </dl>
        </>
      )}
    </>
  );
};
