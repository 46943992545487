import { FolderRemoveIcon, PaperClipIcon } from '@heroicons/react/outline';

type File = {
  path: string;
  name: string;
};

type FilesProps = {
  files: File[];
  emptyMessage?: string;
};

export const Files = ({ emptyMessage = 'No files', files }: FilesProps) => {
  return files.length > 0 ? (
    <ul className="divide-y divide-ebony-clay-100 border border-ebony-clay-100">
      {files.map((file) => (
        <li key={file.path} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
          <div className="flex w-0 flex-1 items-center">
            <PaperClipIcon
              className="h-5 w-5 flex-shrink-0 text-ebony-clay-300"
              aria-hidden="true"
            />
            <span className="ml-2 w-0 flex-1 truncate">{file.name}</span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <a
              href={file.path}
              className="font-medium text-dodger-blue-600 hover:text-dodger-blue-900"
              target="_blank"
              rel="noreferrer"
            >
              Download
            </a>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <div className="border border-ebony-clay-100 bg-white p-2 text-center">
      <FolderRemoveIcon className="mx-auto h-12 w-12  text-ebony-clay-200" aria-hidden="true" />
      <h3 className="mt-2 text-sm font-medium text-ebony-clay-500 capitalize-first">
        {emptyMessage}
      </h3>
    </div>
  );
};
