import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';

interface DownloadFileProps {
  readonly apiDefinition: () => Promise<AxiosResponse<Blob>>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: () => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const download = async () => {
    try {
      preDownloading();
      const data = await apiDefinition();
      fileDownload(data as any, getFileName());
      postDownloading();
    } catch (error) {
      onError();
    }
  };

  return { download };
};
