import { useSearchParams } from 'react-router-dom';

import { Layout } from '../components/Layout';
import { ResetPasswordForm } from '../components/ResetPasswordForm';
import { SendResetForm } from '../components/SendResetForm';

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  return (
    <Layout title="Reset your password">
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-3xl sm:px-10">
          {token ? <ResetPasswordForm /> : <SendResetForm />}
        </div>
      </div>
    </Layout>
  );
};
