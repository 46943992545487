import { ExclamationIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { TextButton } from '@/components/Elements/TextButton';
import { Form as FormComponent, TextAreaField } from '@/components/Form';
import { pluralizeWord } from '@/utils/format';

import { useProcessTimeOffRequest } from '../api/processTimeOffRequest';
import { TTimeOff } from '../types';

type DeclineTimeOffProps = {
  id: number;
  fullName: string;
  startDate: string;
  endDate: string;
  requestType: string;
  description: string;
  ptoBalance: TTimeOff['ptoBalance'];
};

type InfoCardProps = {
  title: string;
  value: ReactNode;
};

const triggerButton = <TextButton variant="danger">Decline</TextButton>;

const InfoCard = ({ title, value }: InfoCardProps) => (
  <div className="col-span-1 h-full bg-ebony-clay-50 p-4 shadow-sm">
    <dt className="text-sm font-medium text-ebony-clay-900">{title}</dt>
    <dd className="mt-1 text-sm text-ebony-clay-600">{value}</dd>
  </div>
);

const schema = z.object({
  comment: z
    .string()
    .min(1, 'Comment should be more than 1 characters')
    .max(255, 'Comment should be less than 256 characters')
    .or(z.literal('')),
});

type FormSchema = z.infer<typeof schema>;

const FORM_ID = 'decline-time-off';

type FormProps = {
  workerFullName?: string;
  onSubmit: (values: FormSchema) => Promise<void>;
};

const Form = ({ onSubmit }: FormProps) => {
  return (
    <FormComponent<FormSchema, typeof schema>
      id={FORM_ID}
      onSubmit={onSubmit}
      schema={schema}
      options={{
        shouldUnregister: true,
      }}
    >
      {({ formState, register }) => {
        return (
          <div className="mt-2 space-y-6 border-gray-200 bg-ebony-clay-50 p-4 shadow-sm">
            <TextAreaField
              label="Comment (optional)"
              error={formState.errors['comment']}
              registration={register('comment')}
            />
          </div>
        );
      }}
    </FormComponent>
  );
};

export const DeclineTimeOff = ({
  id,
  fullName,
  startDate,
  endDate,
  requestType,
  description,
  ptoBalance,
}: DeclineTimeOffProps) => {
  const processTimeOffRequest = useProcessTimeOffRequest();

  const handleConfirmationClick = async ({ comment }: FormSchema) => {
    await processTimeOffRequest.mutateAsync({
      id,
      comment: comment || undefined,
      status: 'rejected',
    });
  };

  const usedDays =
    typeof ptoBalance?.daysTaken === 'number'
      ? `${ptoBalance?.daysTaken} day${pluralizeWord(ptoBalance?.daysTaken)}`
      : undefined;

  const totalDays =
    typeof ptoBalance?.vacationDays === 'number'
      ? `${ptoBalance?.vacationDays} day${pluralizeWord(ptoBalance?.vacationDays)}`
      : undefined;

  return (
    <ModalDialog
      icon={<ExclamationIcon className="h-6 w-6 text-froly-600" />}
      confirmButton={
        <Button
          isLoading={processTimeOffRequest.isLoading}
          disabled={processTimeOffRequest.isLoading || processTimeOffRequest.isSuccess}
          form={FORM_ID}
          variant="danger"
          type="submit"
        >
          Decline
        </Button>
      }
      isDone={processTimeOffRequest.isSuccess}
      title="Decline time off request?"
      triggerButton={triggerButton}
    >
      <dl className="grid gap-2 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <InfoCard title="Worker" value={fullName} />
        </div>
        {ptoBalance?.type && (
          <div className="capitalize sm:col-span-2">
            <InfoCard title="Policy" value={ptoBalance?.type} />
          </div>
        )}
        <div className="sm:col-span-1">
          <InfoCard title="Total" value={totalDays} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Used" value={usedDays} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Start date" value={startDate} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="End date" value={endDate} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Type" value={<span className="capitalize">{requestType}</span>} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Description" value={description} />
        </div>
      </dl>
      <Form onSubmit={handleConfirmationClick} />
    </ModalDialog>
  );
};
