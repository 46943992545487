import { ChevronDownIcon, ChevronUpIcon, MinusSmIcon } from '@heroicons/react/outline';
import { cloneElement, MouseEventHandler, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Spinner } from '../Spinner';

type SortIconProps = {
  icon: ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
};

const SortIcon = ({ icon, isLoading, onClick }: SortIconProps) => (
  <button
    onClick={onClick}
    className="group ml-2 h-5 w-5 rounded bg-ebony-clay-200 text-ebony-clay-900 hover:bg-ebony-clay-300"
  >
    {isLoading ? (
      <Spinner size="xs" variant="primary" className="mx-auto" />
    ) : (
      cloneElement(icon, {
        className: 'mx-auto h-4 w-4 cursor-pointer text-dodger-blue-600',
        'aria-hidden': 'true',
      })
    )}
  </button>
);

type SortComponentProps = {
  columnField: string;
  isSortable?: boolean;
  isLoading: boolean;
};

export const SortComponent = ({
  columnField,
  isLoading,
  isSortable = true,
}: SortComponentProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get('sort');

  if (!isSortable) return null;

  const handleOnClick = (value?: string) => {
    const params = new URLSearchParams(searchParams);
    params.delete('sort');
    params.delete('page');
    if (value) {
      params.append('sort', value);
    }
    setSearchParams(params);
  };

  const defaultSort = () => handleOnClick();
  const descendingSort = () => handleOnClick(`-${columnField}`);
  const ascendingSort = () => handleOnClick(`${columnField}`);

  switch (sort) {
    case `-${columnField}`:
      return <SortIcon icon={<ChevronDownIcon />} isLoading={isLoading} onClick={defaultSort} />;
    case columnField:
      return <SortIcon icon={<ChevronUpIcon />} isLoading={isLoading} onClick={descendingSort} />;
    default:
      return <SortIcon icon={<MinusSmIcon />} isLoading={isLoading} onClick={ascendingSort} />;
  }
};
