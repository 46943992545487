import hotjar from '@hotjar/browser';

import { HOTJAR_HJID, HOTJAR_HJSV } from '@/config';

export default function hotjarInit(userId: string | null, identifyProperties: Record<string, any>) {
  hotjar.init(Number(HOTJAR_HJID), Number(HOTJAR_HJSV));

  if (hotjar.isReady()) {
    hotjar.identify(userId, identifyProperties);
  }
}
