import { DateFilter, Filter } from '@/components/Elements/Filter';
import { FieldWrapper } from '@/components/Form/FieldWrapper';

const filters: { name: string; options: { label: string; value: string }[] }[] = [
  {
    name: 'type',
    options: [
      {
        label: 'all',
        value: '',
      },
      {
        label: 'bonus',
        value: 'bonus',
      },
      {
        label: 'commission',
        value: 'commission',
      },
      {
        label: 'days',
        value: 'days',
      },
      {
        label: 'hours',
        value: 'hours',
      },
      {
        label: 'reimbursement',
        value: 'reimbursement',
      },
      {
        label: 'other',
        value: 'other',
      },
    ],
  },
  {
    name: 'status',
    options: [
      {
        label: 'all',
        value: '',
      },
      {
        label: 'accepted',
        value: 'accepted',
      },
      {
        label: 'rejected',
        value: 'rejected',
      },
      {
        label: 'canceled',
        value: 'canceled',
      },
      {
        label: 'processed',
        value: 'processed',
      },
      {
        label: 'paid',
        value: 'paid',
      },
    ],
  },
];

export const Filters = () => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {filters.map((filter) => (
        <div className="col-span-1" key={filter.name}>
          <FieldWrapper label={filter.name}>
            <Filter name={filter.name} options={filter.options} />
          </FieldWrapper>
        </div>
      ))}
      <DateFilter name="createdBetween" />
    </div>
  );
};
