import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

import { TextButton } from '../Elements/TextButton';

type BackButtonProps = {
  backUrl?: string;
};

const DEFAULT_BACK_PATH = '/app/team';

export const BackButton = ({ backUrl }: BackButtonProps) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    const shouldNativelyNavigateBack = window.history.length > 2;

    if (backUrl) {
      navigate(backUrl, { replace: true });
      return;
    }

    shouldNativelyNavigateBack ? navigate(-1) : navigate(DEFAULT_BACK_PATH, { replace: true });
  };

  return (
    <TextButton
      startIcon={<ArrowLeftIcon className="text-dodger-blue-600 hover:text-dodger-blue-900" />}
      onClick={handleOnClick}
    >
      <span className="font-light">Back</span>
    </TextButton>
  );
};
