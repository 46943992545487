import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { TWorker } from '@/features/workers';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

type AddReimbursementDTO = {
  payee: Payee;
  amount: number;
  type: string;
  description: string;
  currencyCode: string;
};

type Payee = {
  id: number;
};

type UseAddReimbursementOptions = {
  config?: MutationConfig<typeof addReimbursement>;
};

const addReimbursement = (data: AddReimbursementDTO): Promise<void> => {
  return axios.post(`${API_BASE_PATH}/workers/${data.payee.id}/additional-compensations`, data);
};

export const useAddReimbursement = ({ config }: UseAddReimbursementOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('workers');

      const previousWorkers = queryClient.getQueryData<TPaginatedQuery<TWorker[]>>('workers');

      return { previousWorkers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousWorkers) {
        queryClient.setQueryData('workers', context.previousWorkers);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('workers');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: addReimbursement,
  });
};
