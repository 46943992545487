import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { Data } from '@/types';

import { Company } from '../types';

export type UpdateCompanyDTO = {
  legalName: string;
  taxId: string;
  address: Address;
};

type Address = {
  city: string;
  state: string;
  street: string;
  postalCode: string;
  countryCode: string;
};

type CompanyData = Data<Company>;

export const updateCompany = (data: UpdateCompanyDTO): Promise<CompanyData> => {
  return axios.patch(`${API_BASE_PATH}/company`, data);
};

type UseUpdateCompanyOptions = {
  config?: MutationConfig<typeof updateCompany>;
};

export const useUpdateCompany = ({ config }: UseUpdateCompanyOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingCompany) => {
      await queryClient.cancelQueries(['company']);

      const previousCompany = queryClient.getQueryData<CompanyData>(['company']);

      if (previousCompany) {
        queryClient.setQueryData<CompanyData>(['company'], {
          data: {
            ...previousCompany.data,
            taxId: updatingCompany.taxId,
            legalName: updatingCompany.legalName,
            address: {
              ...previousCompany.data.address,
              ...updatingCompany.address,
            },
          },
        });

        return { previousCompany };
      }
    },
    onError: (_, __, context: any) => {
      if (context?.previousCompany) {
        queryClient.setQueryData<CompanyData>(['company'], context.previousCompany);
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries(['company']);
      addNotification({
        type: 'success',
        title: 'Company updated',
      });
    },
    ...config,
    mutationFn: updateCompany,
  });
};
