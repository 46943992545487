import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';

import { MobileSideNavigation, SideNavigationItem } from './Navigation';

const CloseButton = ({
  onCloseClick,
}: {
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
}) => (
  <Transition.Child
    as={Fragment}
    enter="ease-in-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="absolute top-0 right-0 -mr-12 pt-2">
      <button
        type="button"
        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        onClick={onCloseClick}
      >
        <span className="sr-only">Close sidebar</span>
        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>
  </Transition.Child>
);

export const MobileSidebar = ({
  close,
  logo,
  navigation,
}: {
  close: () => void;
  logo: JSX.Element;
  navigation: SideNavigationItem[];
}) => (
  <div className="fixed inset-0 z-40 flex">
    <Transition.Child
      as={Fragment}
      enter="transition ease-in-out duration-300 transform"
      enterFrom="-translate-x-full"
      enterTo="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leaveFrom="translate-x-0"
      leaveTo="-translate-x-full"
    >
      <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
        <CloseButton onCloseClick={close} />
        {logo}
        <div className="mt-5 h-0 flex-1 overflow-y-auto">
          <MobileSideNavigation onItemClick={close} navigation={navigation} />
        </div>
      </Dialog.Panel>
    </Transition.Child>
    <div className="w-14 flex-shrink-0" aria-hidden="true">
      {/* Dummy element to force sidebar to shrink to fit close icon */}
    </div>
  </div>
);
