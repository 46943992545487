import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { NativeCountrySelect, State } from '@/features/countries';
import { Authorization, ROLES } from '@/lib/authorization';

import { useCompany } from '../api/getCompany';
import { UpdateCompanyDTO, useUpdateCompany } from '../api/updateCompany';
import { Company } from '../types';

const companySchema = z.object({
  legalName: z.string().min(1, 'Required'),
  taxId: z.string().min(1, 'Required'),
  street: z.string().min(1, 'Required'),
  city: z.string().min(1, 'Required'),
  state: z.string().min(1, 'Required'),
  postalCode: z.string().min(1, 'Required'),
  countryCode: z.string().min(1, 'Required'),
});

const formDefaultValues = (company: Company) => {
  return {
    defaultValues: {
      city: company.address?.city || undefined,
      countryCode: company.address?.countryCode || undefined,
      postalCode: company.address?.postalCode || undefined,
      state: company.address?.state || undefined,
      street: company.address?.street || undefined,
      legalName: company.legalName || undefined,
      taxId: company.taxId || undefined,
    },
  };
};

export type TBillingInformationSchema = z.infer<typeof companySchema>;

const updateCompanyDTO = (formData: TBillingInformationSchema): UpdateCompanyDTO => {
  return {
    address: {
      city: formData.city,
      countryCode: formData.countryCode,
      postalCode: formData.postalCode,
      state: formData.state,
      street: formData.street,
    },
    legalName: formData.legalName,
    taxId: formData.taxId,
  };
};

export const BillingInformationForm = () => {
  const companyQuery = useCompany();
  const updateCompanyMutation = useUpdateCompany();
  const handleOnSubmit = async (values: TBillingInformationSchema) => {
    await updateCompanyMutation.mutateAsync(updateCompanyDTO(values));
  };

  if (companyQuery.isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Spinner />
      </div>
    );
  }

  if (!companyQuery.data) return null;

  const company = companyQuery.data.data;

  return (
    <Form<TBillingInformationSchema, typeof companySchema>
      onSubmit={async (payee) => {
        handleOnSubmit(payee);
      }}
      schema={companySchema}
      className="space-y-8 divide-y divide-gray-200"
      options={formDefaultValues(company)}
    >
      {({ formState, register, resetField, watch }) => {
        const countryCode = watch('countryCode');

        return (
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">Billing Information</h3>
                <p className="mt-1 text-sm text-gray-500">
                  Please fill in your company&rsquo;s Legal name, Tax ID, and Business address for
                  monthly billing purposes.
                </p>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 border-b pb-6 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <InputField
                    label="Legal name"
                    error={formState.errors['legalName']}
                    registration={register('legalName')}
                    helperText="Do not include your trade name, DBA, or aliases"
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Tax ID"
                    error={formState.errors['taxId']}
                    registration={register('taxId')}
                  />
                </div>
              </div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <NativeCountrySelect
                    label="Country"
                    error={formState.errors['countryCode']}
                    registration={register('countryCode', {
                      onChange: () => {
                        resetField('state', { defaultValue: '' });
                      },
                    })}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Street"
                    error={formState.errors['street']}
                    registration={register('street')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="City"
                    error={formState.errors['city']}
                    registration={register('city')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <InputField
                    label="Zip / Postal code"
                    error={formState.errors['postalCode']}
                    registration={register('postalCode')}
                  />
                </div>
                <div className="sm:col-span-3">
                  <State countryCode={countryCode} />
                </div>
              </div>
            </div>
            <Authorization allowedRoles={[ROLES.superAdmin, ROLES.admin]}>
              <div className="pt-5">
                <div className="flex justify-end">
                  <Button
                    isLoading={updateCompanyMutation.isLoading}
                    disabled={updateCompanyMutation.isLoading}
                    type="submit"
                    size="lg"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Authorization>
          </div>
        );
      }}
    </Form>
  );
};
