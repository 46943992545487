/* eslint-disable react-hooks/exhaustive-deps */
import { type ChangeEvent, useState, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SelectField, type SelectFieldProps } from '@/components/Form';
import { type Currency } from '@/types';

import { useCostCalculatorDetails } from '../api/getCostCalculatorDetails';
import {
  defaultCurrency,
  defaultCurrencyOption,
  CostCalculatorForm,
  type CostCalculatorFormSchema,
  type CostCalculatorFormSchemaWithOutputCurrency,
  countryCodeToCurrencyCodeMap,
  currencyOptions,
} from '../components/CostCalculatorForm/CostCalculatorForm';
import { SalaryPeriodEnum } from '../types';

import { CostCalculatorDetails } from './CostCalculatorDetails';

const getInitialFormValues = (params = new URLSearchParams()) => ({
  region: params.get('region') || '',
  period: (params.get('period') as SalaryPeriodEnum) || SalaryPeriodEnum.annually,
  countryCode: params.get('countryCode') || '',
  grossSalary: params.get('grossSalary') || '',
  currencyCode: params.get('currencyCode') || defaultCurrency,
});

export const CostCalculatorContainer = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [outputCurrencyCode, setOutputCurrencyCode] = useState<Currency>(defaultCurrency);
  const [allowedCurrencies, setAllowedCurrencies] = useState<SelectFieldProps['options']>([
    defaultCurrencyOption,
  ]);
  const [isFormShouldBeSubmitted, setIsFormShouldBeSubmitted] = useState(false);

  const initialValues = getInitialFormValues(searchParams);

  const costCalculationMutation = useCostCalculatorDetails();
  const costCalculationMutationData = costCalculationMutation?.data?.data;

  const handleOutputCurrencyCodeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setOutputCurrencyCode(e.target.value as Currency);
    setIsFormShouldBeSubmitted(true);
  };

  const handleResetData = () => {
    setSearchParams(new URLSearchParams());
    costCalculationMutation.reset();
  };

  const setCurrencies = (countryCode: string, outputCurrencyCode: Currency = defaultCurrency) => {
    if (!countryCode) {
      setAllowedCurrencies([defaultCurrencyOption]);
    } else {
      const filteredCurrencyOptions = currencyOptions.filter(
        (currency) => currency.value === countryCodeToCurrencyCodeMap[countryCode]
      );

      setAllowedCurrencies([...filteredCurrencyOptions, defaultCurrencyOption]);
    }

    setOutputCurrencyCode(outputCurrencyCode);
  };

  const syncFormDataWithSearchParams = (formValues: CostCalculatorFormSchemaWithOutputCurrency) => {
    const newSearchParams = new URLSearchParams(searchParams);

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key as keyof CostCalculatorFormSchemaWithOutputCurrency];

      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });

    setSearchParams(newSearchParams);
  };

  const getNextCurrency = (formCurrencyCode: string) => {
    if (!costCalculationMutation.isSuccess)
      return searchParams.get('outputCurrencyCode') || formCurrencyCode;

    const isOutputCurrencyCodeAllowed = allowedCurrencies.some(
      (currency) => currency.value === outputCurrencyCode
    );

    return isOutputCurrencyCodeAllowed ? outputCurrencyCode : formCurrencyCode;
  };

  const handleCostCalculatorFormSubmit = async (formValues: CostCalculatorFormSchema) => {
    const nextOutputCurrencyCode = getNextCurrency(formValues.currencyCode);
    const data = {
      ...formValues,
      outputCurrencyCode: nextOutputCurrencyCode,
    };

    setOutputCurrencyCode(nextOutputCurrencyCode as Currency);
    syncFormDataWithSearchParams(data);

    await costCalculationMutation.mutateAsync(data);
  };

  useEffect(() => {
    const countryCode = searchParams.get('countryCode');
    const outputCurrency = searchParams.get('outputCurrencyCode');

    if (countryCode && outputCurrency) setCurrencies(countryCode, outputCurrency as Currency);
  }, []);

  useEffect(() => {
    if (costCalculationMutationData && isFormShouldBeSubmitted) {
      if (formRef.current?.requestSubmit) {
        formRef.current.requestSubmit();
      } else {
        formRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
      }

      setIsFormShouldBeSubmitted(false);
    }
  }, [outputCurrencyCode]);

  const isDetailsVisible =
    !!costCalculationMutationData &&
    !costCalculationMutation.isLoading &&
    !costCalculationMutation.isError;

  return (
    <>
      <CostCalculatorForm
        ref={formRef}
        onSubmit={handleCostCalculatorFormSubmit}
        resetData={handleResetData}
        defaultValues={initialValues}
        isDataFetched={costCalculationMutation.isSuccess}
        isDataLoading={costCalculationMutation.isLoading}
        allowedCurrencies={allowedCurrencies}
        setAllowedCurrencies={setCurrencies}
      />

      {isDetailsVisible && (
        <CostCalculatorDetails
          {...costCalculationMutationData}
          currencySelect={
            <SelectField
              value={outputCurrencyCode}
              options={allowedCurrencies}
              onChange={handleOutputCurrencyCodeChange}
            />
          }
        />
      )}
    </>
  );
};
