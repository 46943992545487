import clsx from 'clsx';
import { Link as RouterLink, LinkProps } from 'react-router-dom';

export const Link = ({ className, children, ...props }: LinkProps) => (
  <RouterLink
    className={clsx('text-dodger-blue-500 hover:text-dodger-blue-400', className)}
    {...props}
  >
    {children}
  </RouterLink>
);
