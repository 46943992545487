import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

type Datum = {
  name: string;
  path: string;
  type: string;
  collection: string;
  createdAt: string;
}[];

export const getWorkerDocuments = (workerId?: string): Promise<TPaginatedQuery<Datum>> =>
  axios.get(`${API_BASE_PATH}/workers/${workerId}/files?filter[collection]=documents`);

type QueryFnType = typeof getWorkerDocuments;

type UseWorkerDocumentsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useWorkerDocuments = ({ config }: UseWorkerDocumentsOptions = {}) => {
  const { workerId } = useParams();

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['worker-documents-files', workerId],
    queryFn: () => getWorkerDocuments(workerId),
  });
};
