import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useController } from 'react-hook-form';

import { Spinner } from '@/components/Elements';
import { FieldWrapper } from '@/components/Form/FieldWrapper';

import { useCountries } from '../api/getCountries';

type CountryOptions = {
  label: string;
  value: string;
}[];

export const CountryMultiSelect = ({ name }: { name: string }) => {
  const countriesQuery = useCountries({
    config: {
      staleTime: Infinity,
    },
  });

  const {
    field: { onChange },
    fieldState: { error },
  } = useController({ name });

  const [selectedCountries, setSelectedCountries] = useState<CountryOptions>([]);

  if (countriesQuery.isLoading)
    return (
      <FieldWrapper label="Countries to hire in">
        <div
          className={clsx(
            'flex appearance-none',
            'rounded-3xl border border-gray-300',
            'p-2 capitalize shadow-sm',
            'focus:border-ebony-clay-900 focus:ring-ebony-clay-900',
            'justify-end align-middle text-sm'
          )}
        >
          <div className="pointer-events-none my-auto">
            <Spinner size="sm" />
          </div>
        </div>
      </FieldWrapper>
    );

  if (!countriesQuery.data)
    return (
      <FieldWrapper label="Countries to hire in">
        <div
          className={clsx(
            'flex appearance-none',
            'rounded-3xl border border-gray-300',
            'p-2 capitalize shadow-sm',
            'focus:border-ebony-clay-900 focus:ring-ebony-clay-900',
            'justify-end align-middle text-sm'
          )}
        />
      </FieldWrapper>
    );

  const { data: countries } = countriesQuery.data;

  const sortedCountries = countries.map((country) => country).sort();

  const countryOptions: CountryOptions = sortedCountries.map((country) => ({
    value: country.code,
    label: country.name,
  }));

  const handleChange = (value: CountryOptions) => {
    onChange(value);
    setSelectedCountries(value);
  };

  return (
    <FieldWrapper label="Which countries will you hire in?" error={error}>
      <Listbox
        value={selectedCountries}
        onChange={handleChange}
        by="value"
        multiple
        disabled={!countriesQuery.data}
      >
        <Listbox.Button
          as="div"
          tabIndex={0}
          className={clsx(
            'flex cursor-pointer appearance-none',
            'rounded-3xl border border-gray-300',
            'p-2 capitalize shadow-sm',
            'focus:outline-ebony-clay-900',
            'justify-between align-middle text-sm'
          )}
        >
          <div className="flex flex-wrap gap-2 rounded-3xl">
            {selectedCountries?.map((country) => (
              <span
                key={country.value}
                className="text-ellipsis rounded-3xl bg-dodger-blue-500 px-3 text-white"
              >
                {country.label}
              </span>
            ))}
          </div>
          <div className="pointer-events-none my-auto">
            <ChevronDownIcon className="w-5 text-gray-400" aria-hidden="true" />
          </div>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="relative mt-1 max-h-60 w-full overflow-auto rounded-3xl bg-white py-1 text-base shadow-lg ring-1 ring-ebony-clay-900 ring-opacity-5 focus:outline-none sm:text-sm">
            {countryOptions.map((country, index) => (
              <Listbox.Option
                key={index}
                className={({ active }) =>
                  `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-dodger-blue-100 text-dodger-blue-900' : 'text-ebony-clay-900'
                  }`
                }
                value={country}
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {country.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-dodger-blue-500">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </FieldWrapper>
  );
};
