import { cloneElement } from 'react';

import { Link, Spinner } from '@/components/Elements';
import { SelectFieldProps } from '@/components/Form';
import { APP_BASE_PATH } from '@/config';

import { useManagers } from '../api/getManagers';

type ManagerSelectProps = {
  children: JSX.Element;
};

export const ManagerSelect = ({ children }: ManagerSelectProps) => {
  const managersQuery = useManagers();

  if (managersQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!managersQuery.data) return null;

  if (managersQuery.data.data.length < 1)
    return (
      <div className="bg-yellow-50 p-6 text-sm">
        <p>
          Your company does not have any managers on the platform. To invite a manager, create a new
          account in <Link to={`${APP_BASE_PATH}/users`}>Users Dashboard</Link>.
        </p>
      </div>
    );

  const { data } = managersQuery.data;

  const managersOptions: { label: string; value: string }[] = data.map((manager) => ({
    label: `${manager.firstName} ${manager.lastName}`,
    value: manager.id.toString(),
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...managersOptions],
    placeholder: 'loading',
  });
};
