import {
  Link,
  Pagination,
  Pill,
  Spinner,
  Table as TableComponent,
  TableProps,
} from '@/components/Elements';
import { AddReimbursementForm } from '@/features/additionalCompensations';
import { AddTimeOffForm } from '@/features/timeOff';
import { useAuth } from '@/lib/auth';
import { ROLES, useAuthorization } from '@/lib/authorization';
import { StatusColor, TStatusTypes } from '@/types';

import { useWorkers } from '../../api/getWorkers';

import { DownloadWorkerXLS } from './DownloadWorkerXLS';

type TWorker = {
  avatar: string;
  id: number;
  fullName: string;
  jobTitle: string;
  location: string;
  type: string;
  status: string;
  managerName?: string;
  team: string | null;
};

const tableColumns: TableProps<TWorker>['columns'] = [
  {
    title: 'Full Name',
    field: 'fullName',
    Cell: ({ entry: { avatar, fullName, id, jobTitle } }) => (
      <div className="flex items-center">
        <div className="h-11 w-11 flex-shrink-0">
          <img className="h-11 w-11 rounded-full" src={avatar} alt="User avatar" />
        </div>
        <div className="ml-4">
          <Link to={`worker/${id}`}>
            <span className="font-medium text-ebony-clay-900 underline underline-offset-2 hover:text-ebony-clay-700">
              {fullName}
            </span>
          </Link>
          <div className="mt-1 text-gray-500">{jobTitle}</div>
        </div>
      </div>
    ),
  },
  {
    title: 'Location 🌎',
    field: 'location',
  },
  {
    title: 'Type',
    field: 'type',
    Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
  },
  {
    title: 'Manager',
    field: 'managerName',
    isSortable: false,
  },
  {
    title: 'Team',
    field: 'team',
    isSortable: false,
  },
  {
    title: 'Status',
    field: 'status',
    Cell: ({ entry: { status } }) => <Status status={status} />,
  },
];

const Status = ({ status }: Pick<TWorker, 'status'>) => {
  const statusTypes: TStatusTypes = {
    active: StatusColor.Green,
    pending: StatusColor.Yellow,
    terminated: StatusColor.Red,
    created: StatusColor.Yellow,
    filled: StatusColor.Gray,
  };

  return (
    <Pill
      className="text-xs font-semibold uppercase"
      color={statusTypes[status as keyof TStatusTypes]}
    >
      {status}
    </Pill>
  );
};

export const Table = () => {
  const { user } = useAuth();
  const { checkAccess } = useAuthorization();

  const workersQuery = useWorkers({
    config: {
      keepPreviousData: true,
    },
  });

  if (workersQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!workersQuery.data) return null;

  const { data, links, meta } = workersQuery.data;

  const workers: TWorker[] = data.map((worker) => ({
    avatar: worker.avatar,
    fullName: worker.fullName,
    id: worker.id,
    jobTitle: worker.offer.jobTitle,
    location: worker.offer.country.name,
    status: worker.offer.status,
    type: worker.offer.type,
    managerName: worker.offer.manager?.name,
    team: worker.offer.team,
  }));

  const columns = [
    ...tableColumns,
    ...(checkAccess({ allowedRoles: [ROLES.superAdmin, ROLES.admin, ROLES.manager] })
      ? [
          {
            field: 'id',
            isSortable: false,
            isNarrowColumn: true,
            Cell({ entry: { fullName, id, status } }: { entry: TWorker }) {
              if (status === 'terminated') return null;

              return (
                <div className="flex justify-center">
                  <AddReimbursementForm fullName={fullName} id={id} />
                </div>
              );
            },
          },
          {
            field: 'id',
            isSortable: false,
            isNarrowColumn: true,
            Cell({ entry: { fullName, id, type, status } }: { entry: TWorker }) {
              if (status === 'terminated') return null;

              return (
                <div className="flex justify-center">
                  {type === 'employee' && <AddTimeOffForm fullName={fullName} id={id} />}
                </div>
              );
            },
          },
        ]
      : []),
  ].filter(Boolean) as TableProps<TWorker>['columns'];

  return (
    <TableComponent<TWorker>
      data={workers}
      emptyState="No workers found"
      isFetching={workersQuery.isFetching && workersQuery.isPreviousData}
      columns={columns}
      downloadXLS={
        <DownloadWorkerXLS companyName={user?.data.company.name || ''} title="Workers" />
      }
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/team"
          isFetching={workersQuery.isFetching && workersQuery.isPreviousData}
        />
      }
    />
  );
};
