import { PropsWithChildren, ReactNode } from 'react';

import { Spinner } from '@/components/Elements';
import { CurrencyValue } from '@/features/countries';
import { AssignManagerForm } from '@/features/users';

import { useWorker } from '../api/getWorker';

type DetailEntryProps = {
  className?: string;
  label: string;
  value?: ReactNode;
};

const DetailEntry = ({ className, label, value }: DetailEntryProps) => {
  if (!value) return null;

  return (
    <div className={className}>
      <dt className="text-sm font-medium text-ebony-clay-500 capitalize-first">{label}</dt>
      <dd className="mt-1 text-sm text-ebony-clay-900">{value}</dd>
    </div>
  );
};

type DetailsSectionProps = {
  title: string;
};

const DetailsSection = ({ children, title }: PropsWithChildren<DetailsSectionProps>) => (
  <>
    <h3 className="text-lg font-medium text-ebony-clay-900">{title}</h3>
    {children}
  </>
);

export const WorkerDetails = () => {
  const workerQuery = useWorker();

  if (workerQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerQuery.data) return null;

  const worker = workerQuery.data.data;

  return (
    <>
      <DetailsSection title="Worker details">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <DetailEntry
            className="sm:col-span-1"
            label="Personal email address"
            value={worker.email}
          />
          <DetailEntry className="sm:col-span-1" label="Birth date" value={worker.birthDate} />
          <DetailEntry className="sm:col-span-1" label="Phone number" value={worker.phoneNumber} />
          <DetailEntry className="sm:col-span-1" label="Status" value={worker.offer.status} />
          <DetailEntry
            className="sm:col-span-1"
            label="Start date"
            value={worker.offer.startDate}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="End date"
            value={worker.offer.endDate || 'Indefinite'}
          />
          <DetailEntry
            className="sm:col-span-2"
            label="Description of services"
            value={worker.offer.servicesDescription}
          />
        </dl>
      </DetailsSection>
      <DetailsSection title="Payment method">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <DetailEntry
            className="sm:col-span-1"
            label="Account"
            value={worker.offer.bankDetails?.details.account}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="Account type"
            value={worker.offer.bankDetails?.details.type}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="CLABE"
            value={worker.offer.bankDetails?.details.clabe}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="Institution number"
            value={worker.offer.bankDetails?.details.institutionNumber}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="Routing number"
            value={worker.offer.bankDetails?.details.routingNumber}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="Swift / Bic"
            value={worker.offer.bankDetails?.details.swiftBic}
          />
          <DetailEntry
            className="sm:col-span-1"
            label="Transit code"
            value={worker.offer.bankDetails?.details.transitCode}
          />
        </dl>
      </DetailsSection>
      <DetailsSection title="Compensation">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <DetailEntry
            className="sm:col-span-1"
            label={`${worker.offer.compensation.salaryPeriod} base gross salary`}
            value={
              <CurrencyValue
                value={worker.offer.compensation.salary}
                currencyCode={worker.offer.compensation.currencyCode}
              />
            }
          />
        </dl>
      </DetailsSection>
      <DetailsSection title="Manager">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <DetailEntry
            className="sm:col-span-1"
            label="Name"
            value={
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <p>{worker.offer.manager.name || 'No manager or admin selected'}</p>
                <div>
                  <AssignManagerForm
                    workerId={worker.id}
                    defaultManagerId={worker.offer.manager.id}
                  />
                </div>
              </div>
            }
          />
        </dl>
      </DetailsSection>
    </>
  );
};
