import { useRoutes } from 'react-router-dom';

import { NODE_ENV } from '@/config';
import { Logout, ResetPassword, Thanks, User, VerifyLink } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import hotjarInit from '@/lib/hotjar';
import { Data } from '@/types';

import { onboardingRoutes } from './onboarding';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

const createRoutes = (user?: Data<User> | null) => {
  if (user?.data.verified) {
    return protectedRoutes;
  } else if (user) {
    return onboardingRoutes;
  } else {
    return publicRoutes;
  }
};

export const AppRoutes = () => {
  const auth = useAuth();
  const commonRoutes = [
    { path: 'auth/password/reset', element: <ResetPassword /> },
    { path: '/auth/confirmation', element: <VerifyLink /> },
    { path: '/thanks', element: <Thanks /> },
    {
      path: '/logout',
      element: <Logout />,
    },
  ];

  if (NODE_ENV === 'production') {
    hotjarInit(String(auth.user?.data.id), {
      mail: auth.user?.data.email,
      company: auth.user?.data.company,
      role: auth.user?.data.role,
    });
  }

  const routes = createRoutes(auth.user);
  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
