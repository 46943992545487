import { Search } from '@/components/Elements/Filter';

import { Filters } from './Filters';
import { Table } from './Table';

export const ToReviewTable = () => {
  return (
    <>
      <Search placeholder="Search by worker name" />
      <Filters />
      <Table />
    </>
  );
};
