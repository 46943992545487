import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  id: string;
  label: string;
  value: string;
  name?: string;
  checked?: boolean;
  registration?: Partial<UseFormRegisterReturn>;
};

export const RadioField = ({
  id,
  name,
  value,
  label,
  checked,
  description,
  registration = {},
}: InputFieldProps) => (
  <div className="relative flex items-start">
    <div className="absolute flex h-5 items-center">
      <input
        name={name}
        id={id}
        value={value}
        type="radio"
        defaultChecked={checked}
        className={clsx(
          'h-4 w-4 rounded-full border-ebony-clay-300 text-dodger-blue-600 focus:ring-dodger-blue-500'
        )}
        {...registration}
      />
    </div>
    <div className="ml-7 text-sm">
      <label htmlFor={id} className="font-medium text-ebony-clay-700">
        {label}
      </label>
      {description && (
        <p id={id} className="text-ebony-clay-500">
          {description}
        </p>
      )}
    </div>
  </div>
);
