export const PasswordRequirements = () => (
  <div className="mt-12 rounded-3xl border border-yellow-500 px-6 py-5">
    <p className="mb-2 text-sm underline">Password Requirements</p>
    <div className="space-y-0.5">
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        MUST contain at least 8 characters (12+ recommended)
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        MUST contain at least one uppercase letter
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        MUST contain at least one lowercase letter
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        MUST contain at least one number
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        MUST contain at least one special character (#, $, %, etc.)
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        DON’T use a password from another website
      </p>
      <p className="text-xs">
        <span className="font-semibold text-yellow-500">- </span>
        AVOID including private information like your name or birthday
      </p>
    </div>
  </div>
);
