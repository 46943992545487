import { PlusIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { Authorization, ROLES } from '@/lib/authorization';

export const AddWorkerButton = () => {
  return (
    <Authorization allowedRoles={[ROLES.superAdmin, ROLES.admin]}>
      <Link to="worker/new">
        <Button startIcon={<PlusIcon className="h-4 w-4" />} size="md">
          Add worker
        </Button>
      </Link>
    </Authorization>
  );
};
