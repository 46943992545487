import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { InviteUserForm } from '../components';
import { UsersTable } from '../components/UsersTable';

export const UsersDashboard = () => {
  return (
    <ContentLayout title="Users Dashboard">
      <PageHeading title="Users Dashboard" actionComponents={<InviteUserForm key="InviteUser" />} />
      <UsersTable />
    </ContentLayout>
  );
};
