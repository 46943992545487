import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { SectionHeading } from '@/components/Layout/SectionHeading';
import { SendResetFormStatic } from '@/features/auth';
import { MutePendingNotifications } from '@/features/pendingNotifications';

const title = 'Settings';

export const Settings = () => {
  return (
    <ContentLayout title={title}>
      <>
        <PageHeading title={title} />
        <div className="space-y-12 pt-6">
          <div>
            <SectionHeading title="Notifications" />
            <div className="divide-y divide-gray-200 border-y sm:px-3">
              <MutePendingNotifications />
            </div>
          </div>
          <div>
            <SectionHeading title="Security Zone" />
            <div className="divide-y divide-gray-200">
              <div className="flex flex-col justify-between rounded-md border border-froly-500 p-3 sm:flex-row sm:items-center">
                <>
                  <h3 className="hidden text-sm font-medium text-ebony-clay-900 sm:block">
                    Reset Password
                  </h3>
                  <SendResetFormStatic />
                </>
              </div>
            </div>
          </div>
        </div>
      </>
    </ContentLayout>
  );
};
