import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { BillingInformationForm } from '../components';

export const CompanyProfile = () => {
  return (
    <ContentLayout title="Company Profile">
      <PageHeading title="Company Profile" />
      <BillingInformationForm />
    </ContentLayout>
  );
};
