import { axios } from '@/lib/axios';
import { Data } from '@/types';

import { getCsrfToken } from './getCsrfToken';

export type RegistrationDTO = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  accepted: boolean;
};

export const registration = async (data: RegistrationDTO): Promise<Data<any>> => {
  await getCsrfToken();
  return axios.post(`/onboarding/registration`, data);
};
