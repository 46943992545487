import { ExclamationIcon } from '@heroicons/react/outline';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { TextButton } from '@/components/Elements/TextButton';

import { useDeleteUser } from '../api/deleteUser';

type DeleteUserProps = {
  id: number;
  name: string;
};

const triggerButton = <TextButton variant="danger">Delete</TextButton>;

export const DeleteUser = ({ id, name }: DeleteUserProps) => {
  const deleteUserMutation = useDeleteUser();

  const handleConfirmationClick = async () => {
    await deleteUserMutation.mutateAsync({ id, name });
  };

  return (
    <ModalDialog
      icon={<ExclamationIcon className="h-6 w-6 text-froly-600" />}
      showDismiss={false}
      confirmButton={
        <Button
          isLoading={deleteUserMutation.isLoading}
          onClick={handleConfirmationClick}
          variant="danger"
          disabled={deleteUserMutation.isLoading}
        >
          Delete
        </Button>
      }
      triggerButton={triggerButton}
      title={`Are you sure you want to delete ${name}?`}
      isDone={deleteUserMutation.isSuccess}
    />
  );
};
