import { cloneElement, ReactElement } from 'react';

import { SelectFieldProps } from '@/components/Form';

import { useTeams } from '../api/getTeams';

type SelectTeamProps = {
  children: ReactElement;
};

export const SelectTeam = ({ children }: SelectTeamProps) => {
  const teamsQuery = useTeams();

  if (teamsQuery.isLoading) return cloneElement<SelectFieldProps>(children, { disabled: true });

  if (!teamsQuery.data) return children;

  const teams = teamsQuery.data?.data.teams;
  const options: SelectFieldProps['options'] = teams.map((team) => ({
    label: team.name,
    value: team.value,
  }));

  return cloneElement<SelectFieldProps>(children, {
    options: [{ label: '', value: '' }, ...options],
  });
};
