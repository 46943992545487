import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';

import { CostCalculatorContainer } from '../components/CostCalculatorContainer';

export const CostCalculator = () => (
  <ContentLayout title="Cost Calculator">
    <PageHeading
      description="Plan the total cost of employment ahead of time."
      title="Cost Calculator"
    />
    <CostCalculatorContainer />
  </ContentLayout>
);
