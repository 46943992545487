import { useFormContext } from 'react-hook-form';

import { InputField, SelectField } from '@/components/Form';
import { US_STATES } from '@/config';

import { TUpdateCompanySchema } from '../../onboarding/components/UpdateCompanyForm';

const usStateOptions = US_STATES.map((state) => ({
  label: state.title,
  value: state.code,
}));

export const State = ({ countryCode }: { countryCode: string }) => {
  const { formState, register } = useFormContext<TUpdateCompanySchema>();

  return countryCode === 'US' ? (
    <SelectField
      label="State"
      error={formState.errors['state']}
      registration={register('state')}
      options={usStateOptions}
    />
  ) : (
    <InputField
      label="State or Province"
      error={formState.errors['state']}
      registration={register('state')}
    />
  );
};
