import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { type MutationConfig } from '@/lib/react-query';
import { type Data } from '@/types';

import { CostCalculatorFormSchemaWithOutputCurrency } from '../components/CostCalculatorForm/CostCalculatorForm';
import { CostCalculatorResponse } from '../types';

export const getCostCalculatorDetails = async (
  formData: CostCalculatorFormSchemaWithOutputCurrency
): Promise<Data<CostCalculatorResponse>> =>
  await axios.post(`${API_BASE_PATH}/cost-calculator`, formData);

type UseCostCalculatorDetailsOptions = {
  config?: MutationConfig<typeof getCostCalculatorDetails>;
};

export const useCostCalculatorDetails = ({ config }: UseCostCalculatorDetailsOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: getCostCalculatorDetails,
  });
};
