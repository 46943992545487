import { Navigate, Route, Routes } from 'react-router-dom';

import { ReviewedTable } from '../components/ReviewedTable/ReviewedTable';
import { ToReviewTable } from '../components/ToReviewTable/ToReviewTable';

import { TimeOff } from './TimeOff';

export const TimeOffRoutes = () => (
  <Routes>
    <Route path="" element={<TimeOff />}>
      <Route path="" element={<ToReviewTable />} />
      <Route path="reviewed" element={<ReviewedTable />} />
    </Route>
    <Route path="*" element={<Navigate to="" />} />
  </Routes>
);
