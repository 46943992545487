import { default as dayjs } from 'dayjs';
import Dinero from 'dinero.js';

export const formatCurrency = (amount: string, currencyCode: Dinero.Currency) => {
  const format = '0,0.00';
  const convertAmountToDineroInteger = Math.round(parseFloat(amount) * 100);
  const dinero = Dinero({
    amount: convertAmountToDineroInteger,
    currency: currencyCode,
  });

  return { value: dinero.toFormat(format), format };
};

export const formatDate = (date: Date | string) => dayjs(date).format('MM/DD/YYYY');
export const formatDateByMonth = (date: Date | string) => dayjs(date).format('MMMM DD, YYYY');

export const replaceHyphensWithSpaces = (value: string) => value.replace(/-/g, ' ');

export const parsedCountryCode = (currencyCode: string) => currencyCode.slice(0, -1);

export const pluralizeWord = (count: number) => (count !== 1 ? 's' : '');
