import { useMutation, useQueryClient } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

import { User } from '../types';

type DeleteUserDTO = {
  id: number;
  name: string;
};

type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteUser>;
};

const deleteUser = ({ id }: DeleteUserDTO): Promise<void> => {
  return axios.delete(`${API_BASE_PATH}/users/${id}`);
};

export const useDeleteUser = ({ config }: UseDeleteUserOptions = {}) => {
  const queryClient = useQueryClient();
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries(['users']);

      const previousUsers = queryClient.getQueryData<TPaginatedQuery<User[]>>(['users'], {
        exact: false,
      });

      return { previousUsers };
    },
    onError: (_, __, context: any) => {
      if (context?.previousUsers) {
        queryClient.setQueryData(['users'], context.previousUsers);
      }
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['users']);
      addNotification({
        type: 'success',
        title: `${variables.name} was deleted`,
        message: 'If this was unintended please contact support',
      });
    },
    ...config,
    mutationFn: deleteUser,
  });
};
