import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { TPaginatedQuery } from '@/types';

import { TTimeOff } from '../types';

type UseProcessTimeOffRequestOptions = {
  config?: MutationConfig<typeof processTimeOffRequest>;
};

export type ProcessTimeOffRequestDTO = {
  status: string;
  comment?: string;
  id: number;
};

const processTimeOffRequest = ({ id, ...data }: ProcessTimeOffRequestDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/workers/time-off/${id}`, data);
};

export const useProcessTimeOffRequest = ({ config }: UseProcessTimeOffRequestOptions = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries('time-off-to-review');

      const previousTimeOffToReview =
        queryClient.getQueryData<TPaginatedQuery<TTimeOff[]>>('time-off-to-review');

      return { previousTimeOffToReview };
    },
    onError: (_, __, context: any) => {
      if (context?.previousTimeOffToReview) {
        queryClient.setQueryData('time-off-to-review', context.previousTimeOffToReview);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('time-off-to-review');
      addNotification({
        type: 'success',
        title: 'Success',
      });
    },
    ...config,
    mutationFn: processTimeOffRequest,
  });
};
