import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

type Teams = {
  teams: Team[];
};

type Team = {
  name: string;
  value: string;
};

export const getTeams = (): Promise<Data<Teams>> => axios.get(`${API_BASE_PATH}/teams`);

type QueryFnType = typeof getTeams;

type UseTeamsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useTeams = ({ config }: UseTeamsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['teams'],
    queryFn: () => getTeams(),
  });
};
