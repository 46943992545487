import { useNavigate } from 'react-router-dom';

import { Spinner } from '@/components/Elements';

import { useEmailVerify } from '../api/emailVerify';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const emailVerifyQuery = useEmailVerify({
    config: {
      onSuccess: () => {
        navigate('/thanks');
      },
    },
  });

  if (!emailVerifyQuery.data) return null;

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Spinner size="xl" />
    </div>
  );
};
