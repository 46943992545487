import { Filters } from './Filters';
import { Table } from './Table';

export const UsersTable = () => {
  return (
    <>
      <Filters />
      <Table />
    </>
  );
};
