import { Switch } from '@/components/Elements';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';

import { useMutePendingNotifications } from '../api/mutePendingNotifications';

export const MutePendingNotifications = () => {
  const { user, refetchUser } = useAuth();
  const { addNotification } = useNotificationStore();

  const mutePendingNotifications = useMutePendingNotifications({
    config: {
      onSuccess: () => {
        refetchUser();
        addNotification({
          type: 'success',
          title: 'Success',
        });
      },
    },
  });

  const switchOnChange = async (checked: boolean): Promise<void> => {
    await mutePendingNotifications.mutateAsync({ mailSubscription: checked });
  };

  return (
    <Switch
      label="Activate notifications"
      checked={Boolean(user?.data.mailSubscription)}
      onChange={switchOnChange}
      isLoading={mutePendingNotifications.isLoading}
    />
  );
};
