/* eslint-disable react-hooks/exhaustive-deps */
import { type ChangeEvent, useEffect, forwardRef, type MouseEventHandler } from 'react';
import type { UseFormReset, UseFormSetValue } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/Elements';
import { FormWithRef, InputField, SelectField, type SelectFieldProps } from '@/components/Form';

import { SalaryPeriodEnum } from '../../types';

export const canadaRegionOptions: SelectFieldProps['options'] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'British Columbia',
    value: 'BC',
  },
  {
    label: 'Ontario',
    value: 'ON',
  },
];

export const costCalculatorCountryOptions: SelectFieldProps['options'] = [
  {
    label: '',
    value: '',
  },
  {
    label: 'Canada',
    value: 'CA',
  },
  {
    label: 'Colombia',
    value: 'CO',
  },
  {
    label: 'Mexico',
    value: 'MX',
  },
];

export const countryCodeToCurrencyCodeMap: { [key: string]: string } = {
  '': 'USD',
  CA: 'CAD',
  MX: 'MXN',
  CO: 'COP',
} as const;

export const periodOptions: SelectFieldProps['options'] = Object.values(SalaryPeriodEnum).map(
  (period) => ({
    label: period,
    value: period,
  })
);

export const currencyOptions: SelectFieldProps['options'] = Object.values(
  countryCodeToCurrencyCodeMap
).map((currency) => ({
  label: currency,
  value: currency,
}));

export const defaultCurrency = 'USD';

export const defaultCurrencyOption = { value: defaultCurrency, label: defaultCurrency };

export const schema = z
  .object({
    grossSalary: z.string().min(1, 'Required'),
    currencyCode: z.string().min(1, 'Required'),
    countryCode: z.string().min(1, 'Select a country'),
    region: z.string().optional(),
    period: z.nativeEnum(SalaryPeriodEnum),
  })
  .refine((val) => (val.countryCode === 'CA' && val.region) || val.countryCode !== 'CA', {
    message: 'Select a state/province',
    path: ['region'],
  });

const defaultFormValues = {
  region: '',
  countryCode: '',
  grossSalary: '',
  currencyCode: defaultCurrency,
  period: SalaryPeriodEnum.annually,
};

export type CostCalculatorFormSchema = z.infer<typeof schema>;

export type CostCalculatorFormSchemaWithOutputCurrency = CostCalculatorFormSchema & {
  outputCurrencyCode: string;
};

export type CostCalculatorFormProps = {
  onSubmit(formValues: CostCalculatorFormSchema): void;
  resetData(): void;
  isDataFetched: boolean;
  defaultValues: CostCalculatorFormSchema;
  isDataLoading: boolean;
  allowedCurrencies: SelectFieldProps['options'];
  setAllowedCurrencies(countryCode: string): void;
};

export const FORM_ID = 'cost-calculator';

export const CostCalculatorForm = forwardRef<HTMLFormElement, CostCalculatorFormProps>(
  (
    {
      onSubmit,
      resetData,
      defaultValues,
      isDataFetched,
      isDataLoading,
      allowedCurrencies,
      setAllowedCurrencies,
    },
    ref
  ) => {
    const handleCountryCodeChange =
      (setValue: UseFormSetValue<CostCalculatorFormSchema>) =>
      (e: ChangeEvent<HTMLSelectElement>) => {
        const countryCode = e.target.value;

        setAllowedCurrencies(countryCode);
        setValue('countryCode', countryCode, { shouldValidate: true });
        setValue('currencyCode', defaultCurrency);
      };

    const handleFormReset =
      (resetForm: UseFormReset<CostCalculatorFormSchema>): MouseEventHandler<HTMLButtonElement> =>
      (e) => {
        e.preventDefault();

        resetData();
        resetForm(defaultFormValues, { keepErrors: true });
      };

    useEffect(() => {
      if (schema.safeParse(defaultValues).success) onSubmit(defaultValues);
    }, []);

    return (
      <FormWithRef<CostCalculatorFormSchema, typeof schema>
        id={FORM_ID}
        ref={ref}
        schema={schema}
        onSubmit={onSubmit}
        options={{ defaultValues }}
      >
        {({ register, formState, watch, setValue, getValues, reset }) => {
          const countryCode = watch('countryCode');

          return (
            <div className="grid grid-cols-8 gap-6">
              <div
                className={`${countryCode === 'CA' ? 'col-span-8 sm:col-span-4' : 'col-span-8'}`}
              >
                <SelectField
                  label="I would like to hire someone in"
                  error={formState.errors['countryCode']}
                  value={getValues('countryCode')}
                  onChange={handleCountryCodeChange(setValue)}
                  options={costCalculatorCountryOptions}
                />
              </div>

              {countryCode === 'CA' && (
                <div className="col-span-8 sm:col-span-4">
                  <SelectField
                    label="State/Province"
                    registration={register('region')}
                    options={canadaRegionOptions}
                    error={formState.errors['region']}
                  />
                </div>
              )}

              <div className="col-span-8">
                <div className="flex flex-col gap-6 sm:flex-row">
                  <div className="flex flex-1 gap-2">
                    <SelectField
                      className="w-[80px] shrink-0"
                      label="Salary"
                      options={allowedCurrencies}
                      registration={register('currencyCode')}
                    />
                    <div className="w-full">
                      <InputField
                        label={<span>&nbsp;</span>}
                        error={formState.errors['grossSalary']}
                        registration={register('grossSalary')}
                      />
                    </div>
                  </div>

                  <div className="flex-1">
                    <SelectField
                      label="Period"
                      options={periodOptions}
                      registration={register('period')}
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-8 flex justify-end gap-2">
                {isDataFetched ? (
                  <>
                    <Button
                      disabled={isDataLoading}
                      variant="alternative"
                      onClick={handleFormReset(reset)}
                      type="button"
                    >
                      Reset
                    </Button>
                    <Button disabled={isDataLoading} isLoading={isDataLoading} type="submit">
                      Update
                    </Button>
                  </>
                ) : (
                  <Button disabled={isDataLoading} isLoading={isDataLoading} type="submit">
                    {isDataLoading ? 'Calculating...' : 'Calculate'}
                  </Button>
                )}
              </div>
            </div>
          );
        }}
      </FormWithRef>
    );
  }
);

CostCalculatorForm.displayName = 'CostCalculatorForm';
