import { Search } from '@/components/Elements/Filter';
import { SectionHeading } from '@/components/Layout/SectionHeading';

import { AdditionalPaymentsTable } from '../components/AdditionalCompensationsTable/AdditionalPaymentsTable';

export const ToReview = () => {
  return (
    <>
      <SectionHeading title="It looks like you have some additional compensation requests… 👀" />
      <Search placeholder="Search by worker name" />
      <AdditionalPaymentsTable />
    </>
  );
};
