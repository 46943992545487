import { Outlet, useMatch } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { TabNavigation } from '@/components/Layout/TabNavigation';
import { APP_BASE_PATH } from '@/config';
import { Authorization, ROLES } from '@/lib/authorization';

import { WorkerBanner } from '../components/WorkerBanner';

export const WorkerProfile = () => {
  const featureRoute = `${APP_BASE_PATH}/team/worker/:workerId`;
  const tabRoutes = [
    {
      name: 'Profile',
      to: '',
      current: Boolean(useMatch(featureRoute)),
    },
    {
      name: 'Documents',
      to: 'documents',
      current: Boolean(useMatch(`${featureRoute}/documents`)),
    },
    {
      name: 'Benefits',
      to: 'benefits',
      current: Boolean(useMatch(`${featureRoute}/benefits`)),
    },
  ];

  return (
    <ContentLayout title="Worker Profile" hasBackNavigation>
      <WorkerBanner />
      <Authorization allowedRoles={[ROLES.superAdmin, ROLES.admin]}>
        <TabNavigation routes={tabRoutes} />
      </Authorization>
      <Outlet />
    </ContentLayout>
  );
};
