import {
  Link,
  Pagination,
  Spinner,
  Table as TableComponent,
  TableProps,
} from '@/components/Elements';
import { Receipt } from '@/components/Elements/Receipt';
import { CurrencyValue } from '@/features/countries';
import { useAuth } from '@/lib/auth';
import { ROLES, useAuthorization } from '@/lib/authorization';

import { useAdditionalCompensations } from '../../api/getAdditionalCompensations';
import { AcceptAdditionalCompensation } from '../AcceptAdditionalCompensation';
import { DeclineAdditionalCompensation } from '../DeclineAdditionalCompensation';

import { DownloadAdditionalCompensationsXLS } from './DownloadAdditionalCompensationsXLS';

type Compensation = {
  id: number;
  workerId: number;
  avatar: string;
  fullName: string;
  description: string;
  type: string;
  amount: string;
  createdAt: string;
  receipt?: string;
  status: string;
  currencyCode: string;
};

const tableColumns: TableProps<Compensation>['columns'] = [
  {
    title: 'Worker',
    field: 'fullName',
    Cell: ({ entry: { avatar, fullName, workerId } }) => (
      <div className="flex items-center gap-x-2">
        <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
        <Link to={`/app/team/worker/${workerId}`}>
          <span className="font-medium text-ebony-clay-900 underline underline-offset-2 hover:text-ebony-clay-700">
            {fullName}
          </span>
        </Link>
      </div>
    ),
  },
  {
    title: 'Type',
    field: 'type',
    Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
  },
  {
    title: 'Amount',
    field: 'amount',
    Cell: ({ entry: { amount, currencyCode } }) => (
      <CurrencyValue currencyCode={currencyCode} value={amount} />
    ),
    isSortable: false,
  },
  {
    title: 'Date',
    field: 'createdAt',
  },
  {
    title: 'Description',
    field: 'description',
    isSortable: false,
  },
  {
    title: 'Receipt',
    field: 'receipt',
    isSortable: false,
    Cell: ({ entry: { receipt } }) => <Receipt url={receipt} />,
  },
];

export const Table = () => {
  const { user } = useAuth();
  const { checkAccess } = useAuthorization();
  const additionalCompensationsQuery = useAdditionalCompensations({
    config: {
      keepPreviousData: true,
    },
  });

  if (additionalCompensationsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!additionalCompensationsQuery.data) return null;

  const { data, links, meta } = additionalCompensationsQuery.data;

  const compensations: Compensation[] = data.map((compensation) => ({
    workerId: compensation.workerId,
    id: compensation.id,
    avatar: compensation.avatar,
    fullName: compensation.fullName,
    description: compensation.description,
    type: compensation.type,
    amount: compensation.amount,
    createdAt: compensation.createdAt,
    status: compensation.status,
    receipt: compensation.receipt,
    currencyCode: compensation.currencyCode,
  }));

  const columns = [
    ...(checkAccess({ allowedRoles: [ROLES.superAdmin, ROLES.admin, ROLES.manager] })
      ? [
          {
            title: '',
            field: 'id',
            isNarrowColumn: true,
            isSortable: false,
            Cell({
              entry: { id, fullName, type, amount, createdAt, description, currencyCode },
            }: {
              entry: Compensation;
            }) {
              return (
                <div className="flex gap-x-2">
                  <AcceptAdditionalCompensation
                    id={id}
                    fullName={fullName}
                    amount={amount}
                    createdAt={createdAt}
                    requestType={type}
                    description={description}
                    currencyCode={currencyCode}
                  />
                  <DeclineAdditionalCompensation
                    id={id}
                    fullName={fullName}
                    amount={amount}
                    createdAt={createdAt}
                    requestType={type}
                    description={description}
                    currencyCode={currencyCode}
                  />
                </div>
              );
            },
          },
        ]
      : []),
    ...tableColumns,
  ].filter(Boolean) as TableProps<Compensation>['columns'];

  return (
    <TableComponent<Compensation>
      data={compensations}
      emptyState="No additional compensations found"
      isFetching={
        additionalCompensationsQuery.isFetching && additionalCompensationsQuery.isPreviousData
      }
      columns={columns}
      downloadXLS={
        <DownloadAdditionalCompensationsXLS
          companyName={user?.data.company.name || ''}
          title="Additional Compensations"
        />
      }
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/additional-compensation"
          isFetching={
            additionalCompensationsQuery.isFetching && additionalCompensationsQuery.isPreviousData
          }
        />
      }
    />
  );
};
