import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { type ForwardedRef, forwardRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ZodType, ZodTypeDef } from 'zod';

import { FormProps } from './Form';

const Form = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<unknown, ZodTypeDef, unknown>
>(
  { id, schema, options, onSubmit, children, className }: FormProps<TFormValues, Schema>,
  ref: ForwardedRef<HTMLFormElement>
) => {
  const methods = useForm<TFormValues>({ ...options, resolver: schema && zodResolver(schema) });
  return (
    <FormProvider {...methods}>
      <form
        id={id}
        ref={ref}
        noValidate
        className={clsx('space-y-6', className)}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        {children(methods)}
      </form>
    </FormProvider>
  );
};

export const FormWithRef = forwardRef(Form) as <
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends ZodType<unknown, ZodTypeDef, unknown> = ZodType<unknown, ZodTypeDef, unknown>
>(
  props: FormProps<TFormValues, Schema> & { ref?: React.ForwardedRef<HTMLFormElement> }
) => ReturnType<typeof Form>;
