type SectionHeadingProps = {
  actionComponents?: JSX.Element[];
  title?: string;
  description?: string;
};

export const SectionHeading = ({ actionComponents, description, title }: SectionHeadingProps) => {
  return (
    <div className="pb-5 md:flex md:items-center md:justify-between md:space-x-5">
      {title && <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>}
      {description && <p className="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>}
      {actionComponents && (
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          {actionComponents}
        </div>
      )}
    </div>
  );
};
