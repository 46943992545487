export enum SalaryPeriodEnum {
  annually = 'annually',
  monthly = 'monthly',
}

export type CostCalculatorResponse = {
  fee: string;
  grossSalary: string;
  totalNetPay: string;
  totalEmploymentCost: string;
  employerContribution: string;
  employeeContribution: string;
};
