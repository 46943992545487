import { ArchiveIcon } from '@heroicons/react/outline';

import { Link, Pagination, Spinner, Table, TableProps } from '@/components/Elements';
import { CurrencyValue } from '@/features/countries';
import { Authorization, ROLES } from '@/lib/authorization';

import { usePaycycle } from '../api/getPaycycle';

type TEmployeesTakeColumn = {
  avatar: string;
  id: number;
  countryName: string;
  fullName: string;
  type: string;
  currencyCode: string;
  grossBasePay: string;
  bonusCommissions: string;
  totalGrossPay: string;
  workerTaxes: string;
  reimbursements: string;
  totalNetPay: string;
  team?: string;
};

const tableColumns: TableProps<TEmployeesTakeColumn>['columns'] = [
  {
    title: 'Name',
    field: 'fullName',
    isSortable: false,
    Cell: ({ entry: { avatar, fullName, id, type, countryName } }) => (
      <div className="flex items-center gap-x-2">
        <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
        <div className="flex flex-col">
          <Authorization
            allowedRoles={[ROLES.admin, ROLES.superAdmin]}
            forbiddenFallback={<span className="font-medium text-ebony-clay-900">{fullName}</span>}
          >
            <Link to={`/app/team/worker/${id}`}>
              <span className="font-medium text-ebony-clay-900 underline underline-offset-2 hover:text-ebony-clay-700">
                {fullName}
              </span>
            </Link>
          </Authorization>
          <span className="text-xs capitalize leading-3">
            {type} • {countryName}
          </span>
        </div>
      </div>
    ),
  },
  {
    title: 'Team',
    field: 'team',
    isSortable: false,
  },
  {
    title: 'Gross Base Pay',
    field: 'grossBasePay',
    isSortable: false,
    Cell: ({ entry: { currencyCode, grossBasePay } }) => (
      <CurrencyValue value={grossBasePay} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Bonus Commissions',
    field: 'bonusCommissions',
    isSortable: false,
    Cell: ({ entry: { currencyCode, bonusCommissions } }) => (
      <CurrencyValue value={bonusCommissions} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Gross Pay',
    field: 'totalGrossPay',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalGrossPay } }) => (
      <CurrencyValue value={totalGrossPay} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Worker Taxes',
    field: 'workerTaxes',
    isSortable: false,
    Cell: ({ entry: { currencyCode, workerTaxes } }) => (
      <CurrencyValue value={workerTaxes} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Reimbursements',
    field: 'reimbursements',
    isSortable: false,
    Cell: ({ entry: { currencyCode, reimbursements } }) => (
      <CurrencyValue value={reimbursements} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Net Pay',
    field: 'totalNetPay',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalNetPay } }) => (
      <CurrencyValue value={totalNetPay} currencyCode={currencyCode} />
    ),
  },
];

export const EmployeesTakeTable = () => {
  const paycycleQuery = usePaycycle({
    config: {
      keepPreviousData: true,
    },
  });

  if (paycycleQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!paycycleQuery.data)
    return (
      <div className="flex h-80 flex-col items-center justify-center border border-ebony-clay-100 bg-white text-ebony-clay-500">
        <ArchiveIcon className="h-12 w-12" />
        <h4>{'No entries found'}</h4>
      </div>
    );

  const { data, links, meta } = paycycleQuery.data;

  const employeesTake: TEmployeesTakeColumn[] = data.compensations.map((worker) => ({
    id: worker.id,
    avatar: worker.avatar,
    fullName: worker.fullName,
    countryName: worker.countryName,
    currencyCode: worker.currencyCode,
    team: worker.team,
    type: worker.type,
    totalGrossPay: worker.companyPays.totalGrossPay,
    reimbursements: worker.workersTake.reimbursements,
    bonusCommissions: worker.workersTake.bonusCommissions,
    grossBasePay: worker.workersTake.grossBasePay,
    totalNetPay: worker.workersTake.totalNetPay,
    workerTaxes: worker.workersTake.workerTaxes,
  }));

  return (
    <Table<TEmployeesTakeColumn>
      data={employeesTake}
      isFetching={paycycleQuery.isFetching && paycycleQuery.isPreviousData}
      columns={tableColumns}
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/paycycle/employees-take"
          isFetching={paycycleQuery.isFetching && paycycleQuery.isPreviousData}
        />
      }
    />
  );
};
