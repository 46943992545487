import { Navigate, Route, Routes } from 'react-router-dom';

import { PayrollHistory } from '../components/PayrollHistory';

import { Reports } from './Reports';

export const ReportsRoutes = () => (
  <Routes>
    <Route path="" element={<Reports />}>
      <Route path="" element={<PayrollHistory />} />
    </Route>
    <Route path="*" element={<Navigate to="" />} />
  </Routes>
);
