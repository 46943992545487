import { Search } from '@/components/Elements/Filter';
import { SectionHeading } from '@/components/Layout/SectionHeading';

import { CompanyPaysTable } from '../components/CompanyPaysTable';
import { Filters } from '../components/Filters';
import { SelectPaycycleCurrency } from '../components/SelectPaycycleCurrency';

export const CompanyPays = () => {
  return (
    <>
      <SectionHeading
        title="What your company pays 🏦"
        actionComponents={[<SelectPaycycleCurrency key="SelectPaycycleCurrency" />]}
      />
      <Search placeholder="Search by worker name" />
      <Filters />
      <CompanyPaysTable />
    </>
  );
};
