import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Data } from '@/types';

import { PendingNotification } from '../types';

export type DismissPendingNotificationDTO = {
  id: string;
};

export const dismissPendingNotification = ({
  id,
}: DismissPendingNotificationDTO): Promise<void> => {
  return axios.patch(`${API_BASE_PATH}/notifications/${id}`);
};

type UseDismissPendingNotificationOptions = {
  config?: MutationConfig<typeof dismissPendingNotification>;
};

export const useDismissPendingNotification = ({
  config,
}: UseDismissPendingNotificationOptions = {}) => {
  return useMutation({
    onMutate: async (dismissingPendingNotification) => {
      await queryClient.cancelQueries(['pending-notifications']);

      const previousPendingNotifications = queryClient.getQueryData<Data<PendingNotification[]>>([
        'pending-notifications',
      ]);

      if (previousPendingNotifications) {
        const removePendingNotification = (pendingNotification: PendingNotification): boolean =>
          pendingNotification.id !== dismissingPendingNotification.id;

        queryClient.setQueryData<Data<PendingNotification[]>>(['pending-notifications'], {
          data: [...previousPendingNotifications.data].filter(removePendingNotification),
        });

        return { previousPendingNotifications };
      }
    },
    onError: (_, __, context: any) => {
      if (context?.previousPendingNotifications) {
        queryClient.setQueryData<Data<PendingNotification[]>>(
          ['pending-notifications'],
          context.previousPendingNotifications
        );
      }
    },
    onSuccess: () => {
      queryClient.refetchQueries(['pending-notifications']);
    },
    ...config,
    mutationFn: dismissPendingNotification,
  });
};
