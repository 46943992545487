import { useQuery } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Data } from '@/types';

import { Company } from '../types';

export const getCompany = (): Promise<Data<Company>> => axios.get(`${API_BASE_PATH}/company`);

type QueryFnType = typeof getCompany;

type UseCompanyOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCompany = ({ config }: UseCompanyOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['company'],
    queryFn: () => getCompany(),
  });
};
