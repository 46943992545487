import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FieldWrapper } from '@/components/Form/FieldWrapper';

type FilterProps = {
  name: string;
};

export const DateFilter = ({ name }: FilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = searchParams.get(`filter[${name}]`) || '';
  const dateRange = defaultValue?.split(',');
  const [startDate, setStartDate] = useState<string>(dateRange[0]);
  const [endDate, setEndDate] = useState<string>(dateRange[1]);

  useEffect(() => {
    searchParams.delete(`filter[${name}]`);
    searchParams.delete('page');
    if (startDate && endDate) {
      searchParams.append(`filter[${name}]`, `${startDate},${endDate}`);
    }
    setSearchParams(searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  return (
    <>
      <FieldWrapper label="start date">
        <input
          type="date"
          onChange={(event) => {
            setStartDate(event.currentTarget.value);
          }}
          className={clsx(
            'block w-full appearance-none',
            'rounded-3xl border border-gray-300',
            'px-3 py-2 placeholder-gray-400 shadow-sm',
            'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
            'col-span-1 sm:text-sm'
          )}
          value={startDate || ''}
          max={endDate}
        />
      </FieldWrapper>
      <FieldWrapper label="end date">
        <input
          type="date"
          onChange={(event) => {
            setEndDate(event.currentTarget.value);
          }}
          className={clsx(
            'block w-full appearance-none',
            'rounded-3xl border border-gray-300',
            'px-3 py-2 placeholder-gray-400 shadow-sm',
            'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
            'col-span-1 sm:text-sm'
          )}
          value={endDate || ''}
          min={startDate}
        />
      </FieldWrapper>
    </>
  );
};
