import { ArchiveIcon, DownloadIcon } from '@heroicons/react/outline';

import { Spinner } from '@/components/Elements';
import { SectionHeading } from '@/components/Layout/SectionHeading';

import { useReports } from '../api/getReports';

import { SelectYear } from './SelectYear';

export const PayrollHistory = () => {
  const reportsQuery = useReports();

  if (reportsQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!reportsQuery.data) return null;

  const { data } = reportsQuery.data;

  return (
    <>
      <SectionHeading
        title="Payroll History"
        actionComponents={[<SelectYear key="year" name="year" />]}
      />
      {reportsQuery.data.data.length ? (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {data.map((report) => (
            <div
              key={report.url}
              className="relative flex items-center space-x-3 rounded-3xl border border-ebony-clay-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-dodger-blue-500 focus-within:ring-offset-2 hover:border-ebony-clay-400"
            >
              <div className="min-w-0 flex-1">
                <a
                  href={report.url}
                  className="focus:outline-none"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-ebony-clay-900">{report.name}</p>
                  <p className="truncate text-sm text-ebony-clay-500">{report.description}</p>
                </a>
              </div>
              <div className="flex-shrink-0">
                <DownloadIcon className="h-6 w-6 text-ebony-clay-500" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex h-80 flex-col items-center justify-center border border-ebony-clay-100 bg-white text-ebony-clay-500 sm:rounded-3xl">
          <ArchiveIcon className="h-16 w-16" />
          <h4>{'No reports found'}</h4>
        </div>
      )}
    </>
  );
};
