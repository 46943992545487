import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { Company } from './Company';
import { Finished } from './Finished';

const Filled = () => (
  <Routes>
    <Route path="onboarding/finished" element={<Finished />} />
    <Route path="*" element={<Navigate to="onboarding/finished" />} />
  </Routes>
);

export const OnboardingRoutes = () => {
  const { user } = useAuth();

  if (user?.data.company.status !== 'pending') return <Filled />;

  return (
    <Routes>
      <Route path="onboarding/company" element={<Company />} />
      <Route path="*" element={<Navigate to="onboarding/company" />} />
    </Routes>
  );
};
