import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { TTimeOff } from '../types';

export const getReviewedTimeOff = (
  page?: string,
  sort?: string,
  type?: string,
  createdBetween?: string,
  query?: string,
  status = 'accepted,rejected,canceled'
): Promise<TPaginatedQuery<TTimeOff[]>> =>
  axios.get(`${API_BASE_PATH}/workers/time-off`, {
    params: {
      sort,
      page,
      'filter[status]': status,
      'filter[type]': type,
      'filter[createdBetween]': createdBetween,
      'filter[q]': query,
    },
  });

type QueryFnType = typeof getReviewedTimeOff;

type UseReviewedTimeOffOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useReviewedTimeOff = ({ config }: UseReviewedTimeOffOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const status = searchParams.get('filter[status]') || undefined;
  const type = searchParams.get('filter[type]') || undefined;
  const createdBetween = searchParams.get('filter[createdBetween]') || undefined;
  const query = searchParams.get('filter[q]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['reviewed-time-off', page, sort, type, createdBetween, query, status],
    queryFn: () => getReviewedTimeOff(page, sort, type, createdBetween, query, status),
  });
};
