import { useFormContext } from 'react-hook-form';
import { z } from 'zod';

import { InputField, SelectField } from '@/components/Form';

import { SelectTeam } from './SelectTeam';

export const teamFormFragmentSchema = z.object({
  team: z.string().min(1, 'Required').optional(),
  existingTeam: z.string().min(1, 'Required'),
});

type TeamFormFragmentSchema = z.infer<typeof teamFormFragmentSchema>;

export const TeamFormFragment = () => {
  const { formState, register, resetField, watch } = useFormContext<TeamFormFragmentSchema>();
  const existingTeam = watch('existingTeam');

  return (
    <div className="space-y-6 border-t border-gray-200 pt-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">Team Selection</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Assign your new worker to a team (ex: sales, marketing, engineering)
        </p>
      </div>
      <SelectField
        label="I would like to..."
        error={formState.errors['existingTeam']}
        registration={register('existingTeam', {
          onChange: () => {
            resetField('team', { defaultValue: '' });
          },
        })}
        options={[
          { label: '', value: '' },
          { label: 'Add team later', value: 'none' },
          { label: 'Select team', value: 'existing' },
          { label: 'Create new team', value: 'new' },
        ]}
      />
      {existingTeam === 'existing' && (
        <SelectTeam>
          <SelectField
            label="Team"
            error={formState.errors['team']}
            registration={register('team')}
            options={[{ label: '', value: '' }]}
          />
        </SelectTeam>
      )}
      {existingTeam === 'new' && (
        <InputField label="Team" error={formState.errors['team']} registration={register('team')} />
      )}
    </div>
  );
};
