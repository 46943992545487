import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { windowReplaceOriginUrl } from '@/utils/url';

export const logout = async (): Promise<void> => axios.post('/logout');

type UseLogoutOptions = {
  config?: MutationConfig<typeof logout>;
};

export const useLogout = ({ config }: UseLogoutOptions = {}) => {
  const navigate = useNavigate();

  const handleOnSuccess = () => {
    windowReplaceOriginUrl();
  };

  const handleOnError = () => {
    navigate('/');
  };

  return useMutation({
    onError: handleOnError,
    onSuccess: handleOnSuccess,
    ...config,
    mutationFn: () => logout(),
  });
};
