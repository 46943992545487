import { Navigate, Route, Routes } from 'react-router-dom';

import { EmailSent } from './EmailSent';
import { Login } from './Login';
import { LoginLink } from './LoginLink';
import { LoginPasswordless } from './LoginPasswordless';
import { Registration } from './Registration';
import { ResetEmailSent } from './ResetEmailSent';

export const AuthRoutes = () => (
  <Routes>
    <Route path="login" element={<Login />} />
    <Route path="login/passwordless" element={<LoginPasswordless />} />
    <Route path="login/link" element={<LoginLink />} />
    <Route path="login/email-sent" element={<EmailSent />} />
    <Route path="auth/password/reset/email-sent" element={<ResetEmailSent />} />
    <Route path="sign-up" element={<Registration />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

export * from './VerifyLink';
