import * as z from 'zod';

import { Button, Link } from '@/components/Elements';
import { CheckBoxField, Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';

const schema = z.object({
  email: z.string().min(1, 'Required').email(),
  password: z.string().min(1, 'Required'),
  remember: z.boolean(),
});

export type TLoginFormSchema = z.infer<typeof schema>;

export const LoginForm = () => {
  const { login, isLoggingIn } = useAuth();

  return (
    <div>
      <Form<TLoginFormSchema, typeof schema>
        onSubmit={async (values) => {
          await login(values);
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                isLoading={isLoggingIn}
                disabled={isLoggingIn}
                type="submit"
                className="w-full"
              >
                Log in
              </Button>
            </div>
            <div className="flex justify-between">
              <CheckBoxField
                id="remember"
                label="Remember me"
                error={formState.errors['remember']}
                registration={register('remember')}
              />
              <Link to="/auth/password/reset">
                <span className="text-sm">Forgot password?</span>
              </Link>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
