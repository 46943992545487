import clsx from 'clsx';

import { StatusColor } from '@/types';

const colors = {
  [StatusColor.Green]: 'bg-green-100 text-green-700',
  [StatusColor.Yellow]: 'bg-yellow-100 text-yellow-700',
  [StatusColor.Red]: 'bg-red-100 text-red-700',
  [StatusColor.Gray]: 'bg-ebony-clay-100 text-ebony-clay-700',
};

export type PillProps = {
  children: React.ReactNode;
  className?: string;
  color?: keyof typeof colors;
};

export const Pill = ({ children, className, color = StatusColor.Gray }: PillProps) => (
  <span
    className={clsx(
      'inline-flex rounded-full px-2 text-xs font-medium leading-5',
      colors[color],
      className
    )}
  >
    {children}
  </span>
);
