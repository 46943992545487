type ReceiptProps = {
  url?: string;
};

export const Receipt = ({ url }: ReceiptProps) => {
  if (!url) return null;
  return (
    <a
      href={url}
      className="flex items-center justify-center space-x-1 rounded-full focus:underline focus:underline-offset-2 focus:outline-none"
      download
      target="_blank"
      rel="noreferrer"
    >
      <span className="text-sm">Download</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="h-4 w-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
        />
      </svg>
    </a>
  );
};
