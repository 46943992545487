import { Button, Link } from '@/components/Elements';

import { Layout } from '../components/Layout';
import { LoginForm } from '../components/LoginForm';

export const Login = () => (
  <Layout title="Log in to your account">
    <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="mb-3 flex justify-end">
        <a
          href="https://worker.via.work/"
          className="text-sm text-froly-400 hover:text-froly-300"
          target="_blank"
          rel="noreferrer"
        >
          I am a worker &rarr;
        </a>
      </div>

      <div className="bg-white py-8 px-4 shadow sm:rounded-3xl sm:px-10">
        <LoginForm />
        <div className="mt-3">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="bg-white px-2 text-gray-500">OR</span>
            </div>
          </div>
          <div className="mt-3">
            <Link to="passwordless" tabIndex={-1}>
              <Button variant="alternative" className="w-full">
                Send email with login link
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
