import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { AdditionalCompensation } from '../types';

export const getAdditionalCompensations = (
  page?: string,
  sort?: string,
  createdBetween?: string,
  type?: string,
  query?: string
): Promise<TPaginatedQuery<AdditionalCompensation[]>> =>
  axios.get(`${API_BASE_PATH}/workers/additional-compensations`, {
    params: {
      'filter[status]': 'submitted',
      'filter[createdBetween]': createdBetween,
      'filter[type]': type,
      'filter[q]': query,
      sort,
      page,
    },
  });

type QueryFnType = typeof getAdditionalCompensations;

type UseAdditionalCompensationsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAdditionalCompensations = ({ config }: UseAdditionalCompensationsOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const createdBetween = searchParams.get('filter[createdBetween]') || undefined;
  const type = searchParams.get('filter[type]') || undefined;
  const query = searchParams.get('filter[q]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['additional-compensations', page, sort, createdBetween, type, query],
    queryFn: () => getAdditionalCompensations(page, sort, createdBetween, type, query),
  });
};
