import {
  Link,
  Pagination,
  Pill,
  Spinner,
  Table as TableComponent,
  TableProps,
} from '@/components/Elements';
import { useAuth } from '@/lib/auth';
import { StatusColor } from '@/types';

import { useReviewedTimeOff } from '../../api/getReviewedTimeOff';
import { TTimeOff } from '../../types';
import { CancelTimeOff } from '../CancelTimeOff';

import { DownloadReviewedXLS } from './DownloadReviewedXLS';

type TTimeOffColumn = {
  avatar: string;
  id: number;
  workerId: number;
  fullName: string;
  type: string;
  createdAt: string;
  description: string;
  startDate: string;
  endDate: string;
  status: string;
  requesterType: string | null;
  daysRequested?: number;
};

type TStatusColors<Color = StatusColor> = {
  accepted: Color;
  canceled: Color;
  rejected: Color;
};

const Status = ({
  status,
  id,
  requesterType,
}: Pick<TTimeOff, 'status' | 'id' | 'requesterType'>) => {
  const canCancel = status === 'accepted';
  const isClient = requesterType === 'client';

  if (canCancel && isClient) return <CancelTimeOff id={id} />;

  const statusTypes: TStatusColors = {
    accepted: StatusColor.Green,
    canceled: StatusColor.Gray,
    rejected: StatusColor.Red,
  };
  return (
    <Pill
      className="text-xs font-semibold uppercase"
      color={statusTypes[status as keyof TStatusColors]}
    >
      {status}
    </Pill>
  );
};

const tableColumns: TableProps<TTimeOffColumn>['columns'] = [
  {
    title: 'Status',
    field: 'status',
    Cell: ({ entry: { status, id, requesterType } }) => (
      <Status status={status} id={id} requesterType={requesterType} />
    ),
  },
  {
    title: 'Full Name',
    field: 'fullName',
    Cell: ({ entry: { avatar, fullName, workerId } }) => (
      <div className="flex items-center gap-x-2">
        <img className="mr-1 h-8 w-8 rounded-full" src={avatar} alt="user avatar" />
        <Link to={`/app/team/worker/${workerId}`}>
          <span className="font-medium text-ebony-clay-900 underline underline-offset-2 hover:text-ebony-clay-700">
            {fullName}
          </span>
        </Link>
      </div>
    ),
  },
  {
    title: 'Request Type',
    field: 'type',
    Cell: ({ entry: { type } }) => <p className="capitalize-first">{type}</p>,
  },
  {
    title: 'Request Date',
    field: 'createdAt',
  },
  {
    title: 'Start Date',
    field: 'startDate',
    isSortable: false,
  },
  {
    title: 'End Date',
    field: 'endDate',
    isSortable: false,
  },
  {
    title: 'Total days',
    field: 'daysRequested',
    isSortable: false,
  },
  {
    title: 'Description',
    field: 'description',
    isFullWidth: true,
    isSortable: false,
  },
];

export const Table = () => {
  const { user } = useAuth();

  const reviewedTimeOffQuery = useReviewedTimeOff({
    config: {
      keepPreviousData: true,
    },
  });

  if (reviewedTimeOffQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!reviewedTimeOffQuery.data) return null;

  const { data, links, meta } = reviewedTimeOffQuery.data;

  const timeOffRequests: TTimeOffColumn[] = data.map((request) => ({
    avatar: request.avatar,
    fullName: request.fullName,
    workerId: request.workerId,
    id: request.id,
    type: request.type,
    createdAt: request.createdAt,
    description: request.description,
    startDate: request.startDate,
    endDate: request.endDate,
    status: request.status,
    requesterType: request.requesterType,
    daysRequested: request.daysRequested,
  }));

  return (
    <TableComponent<TTimeOffColumn>
      data={timeOffRequests}
      emptyState="No reviewed time off's"
      isFetching={reviewedTimeOffQuery.isFetching && reviewedTimeOffQuery.isPreviousData}
      columns={tableColumns}
      downloadXLS={
        <DownloadReviewedXLS
          companyName={user?.data.company.name || ''}
          title="Reviewed Time Off"
        />
      }
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/time-off/reviewed"
          isFetching={reviewedTimeOffQuery.isFetching && reviewedTimeOffQuery.isPreviousData}
        />
      }
    />
  );
};
