import { useSearchParams } from 'react-router-dom';

import { Well } from '@/components/Elements';
import { Layout } from '@/features/onboarding';

import { RegisterForm } from '../components/RegisterForm';
import { RegistrationForm } from '../components/RegistrationForm';

export const Registration = () => {
  const [searchParams] = useSearchParams();

  const firstName = searchParams.get('firstName') || undefined;
  const lastName = searchParams.get('lastName') || undefined;
  const email = searchParams.get('email') || undefined;

  return (
    <Layout withSignOut={false}>
      <Well>
        {email && firstName && lastName ? (
          <RegisterForm email={email} firstName={firstName} lastName={lastName} />
        ) : (
          <RegistrationForm />
        )}
      </Well>
    </Layout>
  );
};
