import { Currency } from 'dinero.js';

import { formatCurrency } from '@/utils/format';

type CurrencyValueProps = {
  value: string;
  currencyCode: string;
};

export const CurrencyValue = ({ value, currencyCode }: CurrencyValueProps) => {
  const parsedCurrency = formatCurrency(value, currencyCode as Currency);

  return (
    <span className="tracking-wide">
      {parsedCurrency.value}
      <span className="font-sans text-xs tracking-tight text-dodger-blue-600"> {currencyCode}</span>
    </span>
  );
};
