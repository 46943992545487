import { Navigate, Route, Routes } from 'react-router-dom';

import { AdditionalCompensations } from './AdditionalCompensations';
import { Reviewed } from './Reviewed';
import { ToReview } from './ToReview';

export const AdditionalCompensationsRoutes = () => (
  <Routes>
    <Route path="" element={<AdditionalCompensations />}>
      <Route path="" element={<ToReview />} />
      <Route path="reviewed" element={<Reviewed />} />
    </Route>
    <Route path="*" element={<Navigate to="" />} />
  </Routes>
);
