import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { TPaginatedQuery } from '@/types';

import { User } from '../types';

export const getUsers = (
  page?: string,
  sort?: string,
  role?: string
): Promise<TPaginatedQuery<User[]>> =>
  axios.get(`${API_BASE_PATH}/users`, {
    params: {
      sort,
      page,
      'filter[role]': role,
    },
  });

type QueryFnType = typeof getUsers;

type UseUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useUsers = ({ config }: UseUsersOptions = {}) => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || undefined;
  const sort = searchParams.get('sort') || undefined;
  const role = searchParams.get('filter[role]') || undefined;

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['users', page, sort, role],
    queryFn: () => getUsers(page, sort, role),
  });
};
