import { Well } from '@/components/Elements';

import { Layout } from '../components/Layout';
import { UpdateCompanyForm } from '../components/UpdateCompanyForm';

export const Company = () => {
  return (
    <Layout>
      <Well>
        <UpdateCompanyForm />
      </Well>
    </Layout>
  );
};
