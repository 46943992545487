import { Outlet, useMatch } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { PageHeading } from '@/components/Layout/PageHeading';
import { TabNavigation } from '@/components/Layout/TabNavigation';
import { APP_BASE_PATH } from '@/config';

export const AdditionalCompensations = () => {
  const featureRoute = `${APP_BASE_PATH}/additional-compensation`;
  const tabRoutes = [
    {
      name: 'To Review',
      to: '',
      current: Boolean(useMatch(featureRoute)),
    },
    {
      name: 'Reviewed',
      to: 'reviewed',
      current: Boolean(useMatch(`${featureRoute}/reviewed`)),
    },
  ];

  return (
    <ContentLayout title="Reimbursements">
      <PageHeading
        title="Additional Compensation 💰"
        description="Approve additional payments such as reimbursements, commissions, bonuses, and other forms of special compensation."
      />
      <TabNavigation routes={tabRoutes} />
      <Outlet />
    </ContentLayout>
  );
};
