import { Navigate, Route, Routes } from 'react-router-dom';

import { ROLES, useAuthorization } from '@/lib/authorization';

import { WorkerDetails } from '../components/WorkerDetails';

import { AddNewWorker } from './AddNewWorker';
import { WorkerBenefits } from './WorkerBenefits';
import { WorkerDocuments } from './WorkerDocuments';
import { WorkerProfile } from './WorkerProfile';

export const WorkerRoutes = () => {
  const { checkAccess } = useAuthorization();

  return (
    <Routes>
      {checkAccess({ allowedRoles: [ROLES.superAdmin, ROLES.admin] }) && (
        <Route path="new" element={<AddNewWorker />} />
      )}

      <Route path=":workerId" element={<WorkerProfile />}>
        <Route path="" element={<WorkerDetails />} />

        {checkAccess({ allowedRoles: [ROLES.superAdmin, ROLES.admin] }) && (
          <>
            <Route path="documents" element={<WorkerDocuments />} />
            <Route path="benefits" element={<WorkerBenefits />} />
          </>
        )}

        <Route path="*" element={<Navigate to="" />} />
      </Route>
    </Routes>
  );
};

export * from './TeamDashboard';
