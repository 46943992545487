import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { DefaultMutationResponse } from '@/types';

import { getCsrfToken } from './getCsrfToken';

export type LoginCredentialsDTO = {
  email: string;
};

export const resetPasswordEmail = async (
  data: LoginCredentialsDTO
): Promise<DefaultMutationResponse> => {
  await getCsrfToken();
  return axios.post(`${API_BASE_PATH}/password/email`, data);
};

type UseResetPasswordEmailOptions = {
  config?: MutationConfig<typeof resetPasswordEmail>;
};

export const useResetPasswordEmail = ({ config }: UseResetPasswordEmailOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: resetPasswordEmail,
  });
};
