import { Files, Spinner } from '@/components/Elements';

import { useWorkerBenefits } from '../api/getWorkerBenefits';

export const BenefitAttachments = () => {
  const workerBenefitsQuery = useWorkerBenefits();

  if (workerBenefitsQuery.isLoading) {
    return (
      <div className="flex items-center justify-center rounded-3xl border p-4">
        <Spinner />
      </div>
    );
  }

  if (!workerBenefitsQuery.data) return null;

  const workerBenefits = workerBenefitsQuery.data.data;

  return <Files files={workerBenefits} />;
};
