import { ArchiveIcon } from '@heroicons/react/outline';

import { Link, Pagination, Spinner, Table, TableProps } from '@/components/Elements';
import { CurrencyValue } from '@/features/countries';
import { Authorization, ROLES } from '@/lib/authorization';

import { usePaycycle } from '../api/getPaycycle';

type TCompanyPaysColumn = {
  avatar: string;
  id: number;
  countryName: string;
  fullName: string;
  totalGrossPay: string;
  currencyCode: string;
  reimbursements: string;
  totalEmployerTaxes: string;
  totalBenefitsAndOther: string;
  totalEmployerCost: string;
  securityDeposit: string;
  viaFee: string;
  totalDue: string;
  type: string;
  team?: string;
};

const tableColumns: TableProps<TCompanyPaysColumn>['columns'] = [
  {
    title: 'Name',
    field: 'fullName',
    isSortable: false,
    Cell: ({ entry: { avatar, fullName, id, type, countryName } }) => (
      <div className="flex items-center">
        <div className="h-11 w-11 flex-shrink-0">
          <img className="h-11 w-11 rounded-full" src={avatar} alt="User avatar" />
        </div>
        <div className="ml-4">
          <Authorization
            allowedRoles={[ROLES.admin, ROLES.superAdmin]}
            forbiddenFallback={<span className="font-medium text-ebony-clay-900">{fullName}</span>}
          >
            <Link to={`/app/team/worker/${id}`}>
              <span className="font-medium text-ebony-clay-900 underline underline-offset-2 hover:text-ebony-clay-700">
                {fullName}
              </span>
            </Link>
          </Authorization>
          <div className="mt-1 text-gray-500 capitalize-first">
            {type} • {countryName}
          </div>
        </div>
      </div>
    ),
  },
  {
    title: 'Team',
    field: 'team',
    isSortable: false,
  },
  {
    title: 'Total Gross Pay',
    field: 'totalGrossPay',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalGrossPay } }) => (
      <CurrencyValue value={totalGrossPay} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Reimbursements',
    field: 'reimbursements',
    isSortable: false,
    Cell: ({ entry: { currencyCode, reimbursements } }) => (
      <CurrencyValue value={reimbursements} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Employer Taxes',
    field: 'totalEmployerTaxes',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalEmployerTaxes } }) => (
      <CurrencyValue value={totalEmployerTaxes} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Benefits And Other',
    field: 'totalBenefitsAndOther',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalBenefitsAndOther } }) => (
      <CurrencyValue value={totalBenefitsAndOther} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Employer Cost',
    field: 'totalEmployerCost',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalEmployerCost } }) => (
      <CurrencyValue value={totalEmployerCost} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Security Deposit',
    field: 'securityDeposit',
    isSortable: false,
    Cell: ({ entry: { currencyCode, securityDeposit } }) => (
      <CurrencyValue value={securityDeposit} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Via Fee',
    field: 'viaFee',
    isSortable: false,
    Cell: ({ entry: { currencyCode, viaFee } }) => (
      <CurrencyValue value={viaFee} currencyCode={currencyCode} />
    ),
  },
  {
    title: 'Total Due',
    field: 'totalDue',
    isSortable: false,
    Cell: ({ entry: { currencyCode, totalDue } }) => (
      <CurrencyValue value={totalDue} currencyCode={currencyCode} />
    ),
  },
];

export const CompanyPaysTable = () => {
  const paycycleQuery = usePaycycle({
    config: {
      keepPreviousData: true,
    },
  });

  if (paycycleQuery.isLoading) {
    return (
      <div className="flex h-48 w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!paycycleQuery.data)
    return (
      <div className="flex h-80 flex-col items-center justify-center border border-ebony-clay-100 bg-white text-ebony-clay-500">
        <ArchiveIcon className="h-12 w-12" />
        <h4>No entries found</h4>
      </div>
    );

  const { data, links, meta } = paycycleQuery.data;

  const companyPays: TCompanyPaysColumn[] = data.compensations.map((worker) => ({
    id: worker.id,
    avatar: worker.avatar,
    fullName: worker.fullName,
    countryName: worker.countryName,
    currencyCode: worker.currencyCode,
    team: worker.team,
    type: worker.type,
    totalGrossPay: worker.companyPays.totalGrossPay,
    reimbursements: worker.companyPays.reimbursements,
    totalEmployerTaxes: worker.companyPays.totalEmployerTaxes,
    totalBenefitsAndOther: worker.companyPays.totalBenefitsAndOther,
    totalEmployerCost: worker.companyPays.totalEmployerCost,
    securityDeposit: worker.companyPays.securityDeposit,
    viaFee: worker.companyPays.viaFee,
    totalDue: worker.companyPays.totalDue,
  }));

  return (
    <Table<TCompanyPaysColumn>
      data={companyPays}
      isFetching={paycycleQuery.isFetching && paycycleQuery.isPreviousData}
      columns={tableColumns}
      pagination={
        <Pagination
          links={links}
          meta={meta}
          path="/paycycle"
          isFetching={paycycleQuery.isFetching && paycycleQuery.isPreviousData}
        />
      }
    />
  );
};
