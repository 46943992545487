import clsx from 'clsx';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type Currency } from '@/types';
import { formatCurrency } from '@/utils/format';

import { SalaryPeriodEnum } from '../types';

type CostCalculatorDetailsProps = {
  fee: string;
  grossSalary: string;
  totalNetPay: string;
  totalEmploymentCost: string;
  employeeContribution: string;
  employerContribution: string;
  currencySelect: JSX.Element;
};

type CostCalculatorDetailsRowProps = {
  title: string;
  value: string;
  isTotal?: boolean;
};

const CostCalculatorDetailsRow = ({ title, value, isTotal }: CostCalculatorDetailsRowProps) => (
  <div
    className={clsx('flex justify-between text-sm text-gray-500', {
      'font-bold text-gray-700': isTotal,
    })}
  >
    <span className="capitalize">{title}</span>
    <span>{value}</span>
  </div>
);

export const CostCalculatorDetails = ({
  fee,
  grossSalary,
  totalNetPay,
  totalEmploymentCost,
  employeeContribution,
  employerContribution,
  currencySelect,
}: CostCalculatorDetailsProps) => {
  const [searchParams] = useSearchParams();
  const salaryPeriod = useRef(searchParams.get('period'));
  const outputCurrencyCode = useRef(searchParams.get('outputCurrencyCode') as Currency);
  const salaryPeriodTitle =
    salaryPeriod.current === SalaryPeriodEnum.annually ? 'Annual' : 'Monthly';

  const getDetailsValue = (value: string) =>
    `${outputCurrencyCode.current} ${formatCurrency(value, outputCurrencyCode.current).value}`;

  const employerPaysData = [
    {
      title: `Gross ${salaryPeriodTitle} Salary`,
      value: getDetailsValue(grossSalary),
    },
    {
      title: 'Employer Tax Contributions',
      value: getDetailsValue(employerContribution),
    },
    {
      title: 'Via Fee',
      value: getDetailsValue(fee),
    },
    {
      title: `Total ${salaryPeriodTitle} Cost`,
      value: getDetailsValue(totalEmploymentCost),
      isTotal: true,
    },
  ] as const;

  const workerGetsData = [
    {
      title: `Gross ${salaryPeriodTitle} Salary`,
      value: getDetailsValue(grossSalary),
    },
    {
      title: 'Worker Taxes',
      value: getDetailsValue(employeeContribution),
    },
    {
      title: 'Worker Net Salary',
      value: getDetailsValue(totalNetPay),
      isTotal: true,
    },
  ] as const;

  return (
    <div className="grid grid-cols-4 gap-6 pt-6">
      <h2 className="col-span-3 flex items-center text-base font-bold">
        Here’s the {salaryPeriodTitle.toLowerCase()} cost breakdown:
      </h2>
      <div className="col-span-1 min-w-[80px] justify-self-end">{currencySelect}</div>

      <div className="col-span-4 flex flex-col gap-2 rounded-lg bg-ebony-clay-50 p-6 md:col-span-2">
        <h3 className="text-base font-bold">Employer Pays</h3>

        {employerPaysData.map((rowData) => (
          <CostCalculatorDetailsRow key={rowData.title} {...rowData} />
        ))}
      </div>

      <div className="col-span-4 flex flex-col gap-2 rounded-lg bg-ebony-clay-50 p-6 md:col-span-2">
        <h3 className="text-base font-bold">Worker Gets</h3>

        {workerGetsData.map((rowData) => (
          <CostCalculatorDetailsRow key={rowData.title} {...rowData} />
        ))}
      </div>

      <span className="col-span-4 max-w-4xl justify-self-center text-center text-sm text-gray-500">
        <strong className="text-black">Note:</strong> The employer cost, tax, and net salary
        calculations provided here are estimations only and can change based on a number of factors,
        including [but not limited to] age, marital status, industry, and duration of employment.
      </span>
    </div>
  );
};
