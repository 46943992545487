import { Layout } from '../components/Layout';

export const ResetEmailSent = () => (
  <Layout title="Thanks! Please check the link in your email to reset your password">
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow sm:rounded-3xl sm:px-10">
        <div>
          <p>
            If you&rsquo;re having trouble, please check your spam folder or email us at
            support@via.work.
          </p>
        </div>
      </div>
    </div>
  </Layout>
);
