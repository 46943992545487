import { useState, ChangeEvent } from 'react';

import { RadioField } from '@/components/Form';
import { NativeCountrySelect } from '@/features/countries';

import { OnboardContractorForm } from '../OnboardContractor/OnboardContractorForm';
import { OnboardEmployeeForm } from '../OnboardEmployee/OnboardEmployeeForm';

export enum WorkerTypesEnum {
  employee = 'employee',
  contractor = 'contractor',
}

export const AddNewWorkerForm = () => {
  const [workerType, setWorkerType] = useState(WorkerTypesEnum.employee);
  const [workerLocation, setWorkerLocation] = useState('');

  const handleWorkerTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWorkerType(event.target.value as WorkerTypesEnum);
  };

  const handleWorkerLocationChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setWorkerLocation(event.target.value);
  };

  const renderEmployeeForm = () => {
    if (workerLocation && workerType === WorkerTypesEnum.employee) {
      return <OnboardEmployeeForm countryCode={workerLocation} />;
    }
  };

  const renderContractorForm = () => {
    if (workerLocation && workerType === WorkerTypesEnum.contractor) {
      return <OnboardContractorForm countryCode={workerLocation} />;
    }
  };

  return (
    <>
      <div className="border-t pt-6">
        <h2 className="mb-2 text-lg font-medium leading-6 text-gray-900">Choose worker type</h2>
        <h3 className="text-sm font-medium text-gray-700">
          Select the type of worker you&apos;d like to add to your team
        </h3>
      </div>

      <div className="flex items-center gap-10 space-y-1" onChange={handleWorkerTypeChange}>
        <RadioField
          id={`workerType-${WorkerTypesEnum.employee}`}
          label="Full time employee"
          value={WorkerTypesEnum.employee}
          name="workerType"
          checked={workerType === WorkerTypesEnum.employee}
        />
        <RadioField
          id={`workerType-${WorkerTypesEnum.contractor}`}
          label="Contractor"
          value={WorkerTypesEnum.contractor}
          name="workerType"
          checked={workerType === WorkerTypesEnum.contractor}
        />
      </div>

      <NativeCountrySelect label="Worker's location" onChange={handleWorkerLocationChange} />

      {renderEmployeeForm()}
      {renderContractorForm()}
    </>
  );
};
