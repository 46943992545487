import { InformationCircleIcon } from '@heroicons/react/outline';
import { ReactNode } from 'react';

import { Button } from '@/components/Elements';
import { ModalDialog } from '@/components/Elements/ModalDialog';
import { TextButton } from '@/components/Elements/TextButton';
import { pluralizeWord } from '@/utils/format';

import { useProcessTimeOffRequest } from '../api/processTimeOffRequest';
import { TTimeOff } from '../types';

type AcceptTimeOffProps = {
  id: number;
  fullName: string;
  startDate: string;
  endDate: string;
  requestType: string;
  description: string;
  ptoBalance: TTimeOff['ptoBalance'];
};

type InfoCardProps = {
  title: string;
  value: ReactNode;
};

const triggerButton = <TextButton>Accept</TextButton>;

const InfoCard = ({ title, value }: InfoCardProps) => (
  <div className="col-span-1 h-full bg-ebony-clay-50 p-4 shadow-sm">
    <dt className="text-sm font-medium text-ebony-clay-900">{title}</dt>
    <dd className="mt-1 text-sm text-ebony-clay-600">{value}</dd>
  </div>
);

export const AcceptTimeOff = ({
  id,
  fullName,
  startDate,
  endDate,
  requestType,
  description,
  ptoBalance,
}: AcceptTimeOffProps) => {
  const processTimeOffRequest = useProcessTimeOffRequest();

  const handleConfirmationClick = async () => {
    await processTimeOffRequest.mutateAsync({ id, status: 'accepted' });
  };

  const usedDays =
    typeof ptoBalance?.daysTaken === 'number'
      ? `${ptoBalance?.daysTaken} day${pluralizeWord(ptoBalance?.daysTaken)}`
      : undefined;

  const totalDays =
    typeof ptoBalance?.vacationDays === 'number'
      ? `${ptoBalance?.vacationDays} day${pluralizeWord(ptoBalance?.vacationDays)}`
      : undefined;

  return (
    <ModalDialog
      icon={<InformationCircleIcon className="h-6 w-6 text-dodger-blue-600" />}
      confirmButton={
        <Button
          isLoading={processTimeOffRequest.isLoading}
          onClick={handleConfirmationClick}
          disabled={processTimeOffRequest.isLoading || processTimeOffRequest.isSuccess}
        >
          Accept
        </Button>
      }
      isDone={processTimeOffRequest.isSuccess}
      title="Accept time off request?"
      triggerButton={triggerButton}
    >
      <dl className="grid gap-2 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <InfoCard title="Worker" value={fullName} />
        </div>
        {ptoBalance?.type && (
          <div className="capitalize sm:col-span-2">
            <InfoCard title="Policy" value={ptoBalance?.type} />
          </div>
        )}
        <div className="sm:col-span-1">
          <InfoCard title="Total" value={totalDays} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Used" value={usedDays} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="Start date" value={startDate} />
        </div>
        <div className="sm:col-span-1">
          <InfoCard title="End date" value={endDate} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Type" value={<span className="capitalize">{requestType}</span>} />
        </div>
        <div className="sm:col-span-2">
          <InfoCard title="Description" value={description} />
        </div>
      </dl>
    </ModalDialog>
  );
};
