import { Button, Well } from '@/components/Elements';
import { Layout } from '@/features/onboarding';
import { useAuth } from '@/lib/auth';
import { windowReplaceOriginUrl } from '@/utils/url';

export const Thanks = () => {
  const { user } = useAuth();
  const handleContinue = () => {
    windowReplaceOriginUrl();
  };

  const isActiveCompany = user?.data.company.status === 'active';

  return (
    <Layout withSignOut={false}>
      <Well>
        <div className="text-center">
          <h3 className="mt-6 text-sm font-medium text-gray-900">
            Thanks for verifying your account!
          </h3>
          {!isActiveCompany && (
            <p className="mt-1 text-sm text-gray-500">
              Someone from the Via team will reach out shortly so that you can sign the MSA. In the
              meantime, you can access the Via dashboard by clicking
              <span className="font-semibold"> Continue</span> and get started onboarding new
              employees and contractors across the world.
            </p>
          )}
          <div className="mt-6">
            <Button size="lg" onClick={handleContinue}>
              Continue
            </Button>
          </div>
        </div>
      </Well>
    </Layout>
  );
};
