import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { getCsrfToken } from './getCsrfToken';

export type EmailVerifyDTO = {
  user: string | null;
  hash: string | null;
  expires: string | null;
  signature: string | null;
};

export const emailVerify = async ({
  expires,
  hash,
  user,
  signature,
}: EmailVerifyDTO): Promise<void> => {
  await getCsrfToken();
  return axios.get(`${API_BASE_PATH}/email/verify/${user}/${hash}`, {
    params: {
      expires,
      signature,
    },
  });
};

type QueryFnType = typeof emailVerify;

type UseLoginWithLinkOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useEmailVerify = ({ config }: UseLoginWithLinkOptions = {}) => {
  const [searchParams] = useSearchParams();

  const signature = searchParams.get('signature');
  const hash = searchParams.get('hash');
  const expires = searchParams.get('expires');
  const user = searchParams.get('user');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['email-verify'],
    queryFn: () => emailVerify({ expires, hash, user, signature }),
  });
};
