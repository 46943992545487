import { ContentLayout, PageHeading } from '@/components/Layout';

import { AddNewWorkerForm } from '../components';

export const AddNewWorker = () => (
  <ContentLayout title="Add a new worker" hasBackNavigation>
    <PageHeading title="Add a new worker to your team" />
    <AddNewWorkerForm />
  </ContentLayout>
);
