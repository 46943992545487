import { useMutation } from 'react-query';

import { API_BASE_PATH } from '@/config';
import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Data } from '@/types';

import { AdditionalCompensation } from '../types';

export const cancelAdditionalCompensation = ({ id }: { id: number }) =>
  axios.patch(`${API_BASE_PATH}/workers/additional-compensations/${id}`, {
    status: 'canceled',
  });

type UseCancelAdditionalCompensationOptions = {
  config?: MutationConfig<typeof cancelAdditionalCompensation>;
};

export const useCancelAdditionalCompensation = ({
  config,
}: UseCancelAdditionalCompensationOptions = {}) => {
  return useMutation({
    onMutate: async (cancelAdditionalCompensation) => {
      await queryClient.cancelQueries('reviewed-additional-compensations');

      const previousAdditionalCompensations = queryClient.getQueryData<
        Data<AdditionalCompensation[]>
      >('reviewed-additional-compensations');

      queryClient.setQueryData(
        'reviewed-additional-compensations',
        previousAdditionalCompensations?.data?.filter(
          (additionalCompensation) => additionalCompensation.id !== cancelAdditionalCompensation.id
        )
      );

      return { previousAdditionalCompensations };
    },
    onError: (_, __, context: any) => {
      if (context?.previousAdditionalCompensations) {
        queryClient.setQueryData(
          'reviewed-additional-compensations',
          context.previousAdditionalCompensations
        );
      }
    },
    onSuccess: () => queryClient.invalidateQueries('reviewed-additional-compensations'),
    ...config,
    mutationFn: cancelAdditionalCompensation,
  });
};
