import { Button } from '@/components/Elements';
import { Form } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import { useNotificationStore } from '@/stores/notifications';

import { useResetPasswordEmail } from '../api/resetPasswordEmail';

type LoginValues = {
  email: string;
};

export const SendResetFormStatic = () => {
  const { user } = useAuth();
  const { addNotification } = useNotificationStore();
  const resetPasswordEmail = useResetPasswordEmail({
    config: {
      onSuccess: () => {
        addNotification({
          title: 'Success',
          message: 'Check your email for the reset link',
          type: 'success',
        });
      },
    },
  });

  return (
    <div>
      <Form<LoginValues>
        onSubmit={async () => {
          await resetPasswordEmail.mutateAsync({ email: user?.data.email || '' });
        }}
        id="send-reset-form-static"
      >
        {() => (
          <Button
            isLoading={resetPasswordEmail.isLoading}
            disabled={resetPasswordEmail.isLoading}
            type="submit"
            className="w-full"
            variant="danger"
            form="send-reset-form-static"
          >
            Send password reset email
          </Button>
        )}
      </Form>
    </div>
  );
};
