import { SearchIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { FormEvent, InputHTMLAttributes, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button } from '../Button';

type SearchProps = {
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder'];
};

export const Search = ({ placeholder }: SearchProps) => {
  const searchQueryInputRef = useRef<HTMLInputElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = searchParams.get(`filter[q]`) || undefined;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    searchParams.delete(`filter[q]`);
    searchParams.delete('page');

    if (searchQueryInputRef?.current?.value) {
      searchParams.append(`filter[q]`, searchQueryInputRef?.current?.value);
    }

    setSearchParams(searchParams);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label className={clsx('block text-sm font-medium text-gray-700 capitalize-first')}>
        Search
        <div className="mt-1 flex flex-col gap-2 sm:flex-row sm:gap-0">
          <input
            name="searchQuery"
            ref={searchQueryInputRef}
            className={clsx(
              'w-full appearance-none',
              'rounded-full border border-gray-300 sm:rounded-r-none',
              'px-3 py-2 shadow-sm',
              'focus:border-dodger-blue-600 focus:ring-dodger-blue-600',
              'truncate sm:text-sm'
            )}
            defaultValue={defaultValue}
            placeholder={placeholder}
          />
          <Button type="submit" className="sm:rounded-l-none">
            <SearchIcon className="h-4 w-4" />
          </Button>
        </div>
      </label>
    </form>
  );
};
