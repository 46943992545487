import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';

import { useOnboardWorker } from '../../api/onboardWorker';

import { ActionForm, FORM_ID } from './ActionForm';
import { onboardEmployeeDTO } from './onboardEmployeeDTO';
import { OnboardEmployeeSchema } from './schema';

type OnboardEmployeeFormProps = {
  countryCode: string;
};

export const OnboardEmployeeForm = ({ countryCode }: OnboardEmployeeFormProps) => {
  const navigate = useNavigate();
  const onboardWorkerMutation = useOnboardWorker();

  const handleOnSubmit = async (formData: OnboardEmployeeSchema) => {
    await onboardWorkerMutation.mutateAsync(onboardEmployeeDTO({ ...formData, countryCode }));
  };

  if (onboardWorkerMutation.isSuccess) {
    navigate('/app/team');
  }

  return (
    <ActionForm
      onSubmit={handleOnSubmit}
      countryCode={countryCode}
      submitButton={
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={onboardWorkerMutation.isLoading}
          disabled={onboardWorkerMutation.isLoading}
        >
          Submit
        </Button>
      }
    />
  );
};
