import { Outlet, useMatch } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout';
import { TabNavigation } from '@/components/Layout/TabNavigation';
import { APP_BASE_PATH } from '@/config';

import { ActivePaycycle } from '../components/ActivePaycycle';

export const Paycycle = () => {
  const featureRoute = `${APP_BASE_PATH}/paycycle`;
  const tabRoutes = [
    {
      name: 'Company pays',
      to: '',
      current: Boolean(useMatch(featureRoute)),
    },
    {
      name: 'Worker take-home pay',
      to: 'workers-take',
      current: Boolean(useMatch(`${featureRoute}/workers-take`)),
    },
  ];

  return (
    <ContentLayout title="Paycycle">
      <ActivePaycycle />
      <TabNavigation routes={tabRoutes} />
      <Outlet />
    </ContentLayout>
  );
};
